import React, { Component } from 'react';
//import Datas from '../../data/instructor/instructor.json';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
//import Pagination from './../../components/Pagination';
import Footer from '../../components/Footer';
import { Styles } from './styles/instructor.js';

class Instructor extends Component {
	//Construtor para listar pesquisadores
	constructor(props) {
		super(props);
		this.idAnt = null;
		this.idNov = null;
		this.state=({
			db: []
		});
		//this.listarPesquisadores(this.props.match.params.idTipoPesquisador);
	}
	
	listarPesquisadores(idTipoPesquisador) {
			fetch("http://www.gpefed.com.br/src/api/?acao=listarPesquisadores&idTipoPesquisador="+idTipoPesquisador)
			.then((response)=>response.json())
			.then((responseJson)=>
			{
				this.setState({
					db: responseJson
				});
				//console.log(this.state.db);
			})
	}
	
	render() {
		//console.log( this.props );
		this.idNov = this.props.match.params.idTipoPesquisador;
		if( this.idAnt !== this.idNov ) {
			this.listarPesquisadores(this.props.match.params.idTipoPesquisador);
			this.idAnt = this.props.match.params.idTipoPesquisador;
		}
		
        return (
            <Styles>
                {/* Main Wrapper */}
                <div className="main-wrapper instructor-page">

                    {/* Header 2 */}
                    <HeaderTwo />

                    {/* Breadcroumb */}
					<BreadcrumbBox title={this.props.match.params.page} />

                    {/* Instructor Area */}
                    <section className="instructor-area">
                        <Container>
                            <Row>
                                {
									this.state.db.map((data, i) => (
                                        <Col lg="3" md="4" sm="6" key={i}>
                                            <div className="instructor-item">
                                                <Link to={process.env.PUBLIC_URL + `/instructor-details/${data.id}/${this.props.match.params.page}`}><img src={process.env.PUBLIC_URL + `/assets/images/${data.foto}`} alt="" className="img-fluid" /></Link>
                                                <div className="img-content text-center">
                                                    <h5><Link to={process.env.PUBLIC_URL + `/instructor-details/${data.id}/`}>{data.nome}</Link></h5>
                                                    <p>{data.legenda}</p>
														{/*<ul className="list-unstyled list-inline">
                                                        <li className="list-inline-item"><a href={process.env.PUBLIC_URL + data.socialLinks.facebook}><i className="fab fa-facebook-f"></i></a></li>
                                                        <li className="list-inline-item"><a href={process.env.PUBLIC_URL + data.socialLinks.twitter}><i className="fab fa-twitter"></i></a></li>
                                                        <li className="list-inline-item"><a href={process.env.PUBLIC_URL + data.socialLinks.youtube}><i className="fab fa-youtube"></i></a></li>
														</ul>*/}
                                                </div>
                                            </div>
                                        </Col>
                                    ))
                                }

                                {/*<Col md="12" className="text-center">
                                    <Pagination />
                                </Col>*/}
                            </Row>
                        </Container>
                    </section>

                    {/* Footer 1 */}
                    <Footer />

                </div>
            </Styles>
        )
    }
}

export default Instructor