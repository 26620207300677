import React, { Component } from 'react';
//import Datas from '../../data/blog/grid.json';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
//import Pagination from './../../components/Pagination';
import { StylesPaginacao } from "./styles/pagination.js";
//import BlogSidebar from './components/BlogSidebar';
import Footer from '../../components/Footer';
import { Styles } from './styles/blog.js';

class BlogGrid extends Component {
	//Construtor para listar blog
	constructor(props) {
		super(props);
		this.pgAnt = null;
		this.pgNov = null;
		this.state=({
			db: [],
			dbPaginacao: []
		});
		this.paginacaoBlog();
	}
	
	listarBlog(pagina) {
			fetch("http://www.gpefed.com.br/src/api/?acao=listarBlog&pagina="+pagina)
			.then((response)=>response.json())
			.then((responseJson)=>
			{
				this.setState({
					db: responseJson
				});
				//console.log(this.state.db);
			})
	}
	
	paginacaoBlog() {
			fetch("http://www.gpefed.com.br/src/api/?acao=paginacaoBlog")
			.then((response)=>response.json())
			.then((responseJsonPaginacao)=>
			{
				this.setState({
					dbPaginacao: responseJsonPaginacao
				});
				//console.log(this.state.dbPaginacao);
			})
	}
	
    render() {
		this.pgNov = this.props.match.params.pagina;
		if( this.pgAnt !== this.pgNov ) {
			this.listarBlog(this.props.match.params.pagina);
			this.pgAnt = this.props.match.params.pagina;
		}
        return (
            <Styles>
                {/* Main Wrapper */}
                <div className="main-wrapper blog-grid-page">

                    {/* Header 2 */}
                    <HeaderTwo />

                    {/* Breadcroumb */}
                    <BreadcrumbBox title="Blog" />

                    {/* Blog Classic */}
                    <section className="blog-grid-area">
                        <Container>
                            <Row>
                                <Col lg="9" md="8" sm="7">
                                    <Row>
                                        {
                                            this.state.db.map((data, i) => (
                                                <Col lg="6" md="12" key={i}>
                                                    <div className="blog-item">
                                                        <div className="blog-img">
                                                            <Link to={process.env.PUBLIC_URL + `/blog-details_${data.id}`}><img src={process.env.PUBLIC_URL + `/assets/images/${data.foto}`} alt="" className="img-fluid" /></Link>
                                                        </div>
                                                        <div className="blog-content">
                                                            <div className="blog-auth_date d-flex">
                                                                <div className="author-img d-flex">
                                                                    <Link to={process.env.PUBLIC_URL + `/blog-details_${data.id}`}><img src={process.env.PUBLIC_URL + `/assets/images/${data.fotoAutor}`} alt="" /></Link>
                                                                    <p><Link to={process.env.PUBLIC_URL + `/blog-details_${data.id}`}>{data.autor}</Link></p>
                                                                </div>
                                                                <div className="post-date">
                                                                    <p><i className="las la-calendar"></i> {data.data}</p>
                                                                </div>
                                                            </div>
                                                            <div className="blog-title">
                                                                <h6><Link to={process.env.PUBLIC_URL + `/blog-details_${data.id}`}>{data.titulo}</Link></h6>
                                                            </div>
                                                            <div className="blog-desc">
                                                                <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{data.resumo}</p>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </Col>
                                            ))
                                        }
                                    </Row>

                                    <div className="text-center">
										<StylesPaginacao>
											{/* Pagination */}
											<ul className="pagination-box list-unstyled list-inline">
											{/*<li className="list-inline-item"><Link to={process.env.PUBLIC_URL + "/"}><i className="las la-angle-double-left"></i></Link></li>*/}
													{
														this.state.dbPaginacao.map((dataPaginacao, i) => {
															if ( String(dataPaginacao.pagina) === String(this.props.match.params.pagina) ) { 
																return <li className="active list-inline-item"><Link to={process.env.PUBLIC_URL + `/events/${dataPaginacao.pagina}`}>{dataPaginacao.pagina}</Link></li>
															} else {
																return <li className="list-inline-item"><Link to={process.env.PUBLIC_URL + `/blog/${dataPaginacao.pagina}`}>{dataPaginacao.pagina}</Link></li>
															}
														})
													}
												{/*<li className="list-inline-item"><Link to={process.env.PUBLIC_URL + "/"}>2</Link></li>
												<li className="active list-inline-item"><Link to={process.env.PUBLIC_URL + "/"}>3</Link></li>
												<li className="list-inline-item"><Link to={process.env.PUBLIC_URL + "/"}>...</Link></li>
												<li className="list-inline-item"><Link to={process.env.PUBLIC_URL + "/"}>13</Link></li>*/}
												{/*<li className="list-inline-item"><Link to={process.env.PUBLIC_URL + "/"}><i className="las la-angle-double-right"></i></Link></li>*/}
											</ul>
										</StylesPaginacao>
                                    </div>
                                </Col>
                                <Col lg="3" md="4" sm="5">
									{/*<BlogSidebar />*/}
                                </Col>
                            </Row>
                        </Container>
                    </section>

                    {/* Footer */}
                    <Footer />

                </div>
            </Styles>
        )
    }
}

export default BlogGrid