import styled from "styled-components";
import { colors } from "../../../components/common/element/elements.js"

export const Styles = styled.div`
    .about-us {
        padding: 78px 0 85px;

        .about-image {
            position: relative;

            img.main-img {
                width        : 90%;
                border-radius: 5px;

                @media(max-width: 767px) {
                    display : none;
                }
            }

            img.pattern-img {
                position: absolute;
                top     : 100%;
                left    : 0;

                @media(max-width: 1199px) {
                    max-width : 100%;
                }

                @media(max-width: 767px) {
                    display : none;
                }
            }

            .video-player {
                position           : absolute;
                bottom             : -83px;
                right              : 0;
                width              : 205px;
                height             : 255px;
                background-size    : cover;
                background-position: center;
                background-repeat  : no-repeat;
                border-radius      : 5px;

                &::before {
                    position        : absolute;
                    content         : '';
                    background-color: rgba(0, 0, 0, 0.2);
                    width           : 100%;
                    height          : 100%;
                    top             : 0;
                    left            : 0;
                    border-radius : 5px;
                }

                button.play-button {
                    position  : absolute;
                    z-index   : 10;
                    top       : 80%;
                    left      : 50%;
                    transform : translateX(-50%) translateY(-50%);
                    box-sizing: content-box;
                    display   : block;
                    width     : 32px;
                    height    : 44px;
                    border-radius : 50%;

                    i {
                        position   : relative;
                        font-size  : 40px;
                        color      : ${colors.bg1};
                        z-index    : 11;
                        padding-top: 2px;
                        margin-left: -2px;
                    }

                    &::before {
                        content   : "";
                        position  : absolute;
                        z-index   : 0;
                        left      : 50%;
                        top       : 50%;
                        transform : translateX(-50%) translateY(-50%);
                        display   : block;
                        width     : 70px;
                        height    : 70px;
                        background: #ffffff;
                        border-radius : 50%;
                        animation: pulse-border 1500ms ease-out infinite;
                    }

                    &:after {
                        content   : "";
                        position  : absolute;
                        z-index   : 1;
                        left      : 50%;
                        top       : 50%;
                        transform : translateX(-50%) translateY(-50%);
                        display   : block;
                        width     : 70px;
                        height    : 70px;
                        background: #ffffff;
                        border-radius : 50%;
                        transition : all 200ms;
                    }

                    &:hover {
                        i {
                            color: ${colors.green};
                        }
                    }

                    @keyframes pulse-border {
                        0% {
                            transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
                            opacity  : 1;
                        }

                        100% {
                            transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
                            opacity  : 0;
                        }
                    }
                }

                @media(max-width: 991px) {
                    bottom: -70%;
                }

                @media(max-width: 767px) {
                    position: unset;
                    width: 100%;
                    height: 250px;
                    margin-bottom: 30px;
                }
            }
        }

        .about-content {
			text-align: justify;
            h4.about-title {
                color        : ${colors.black1};
                line-height  : 35px;
                font-weight  : 600;
                margin-bottom: 25px;

                @media(max-width: 575px) {
                    margin-bottom: 15px;
                    font-size: 20px;
                }
            }

            p.about-para {
                font-size    : 15px;
                color        : ${colors.text3};
                line-height  : 25px;
                margin-bottom: 40px;

                span {
                    display   : block;
                    margin-top: 20px;
                }

                @media(max-width: 575px) {
                    margin-bottom : 20px;
                }
            }

            .counter-box {
                h3 {
                    margin-bottom: 10px;

                    span {
                        font-weight: 600;
                    }

                    i {
                        font-size     : 20px;
                        vertical-align: middle;
                    }
                }

                p {
                    font-size  : 14px;
                    color      : ${colors.text3};
                    font-weight: 500;
                }

                @media(max-width: 575px) {
                    display : none;
                }
            }

            .counter-box.box1 {
                h3 {
                    color: ${colors.red};
                }
            }

            .counter-box.box2 {
                h3 {
                    color: ${colors.purple};
                }
            }

            .counter-box.box3 {
                h3 {
                    color: ${colors.blue};
                }
            }

            a.readmore-btn {
                font-size : 14px;
                color     : #fff;
                background: ${colors.gr_bg};
                display   : inline-block;
                width     : 145px;
                height    : 40px;
                text-align: center;
                padding   : 11px;
                border-radius : 5px;
                margin-top: 40px;

                &:hover {
                    background: ${colors.gr_bg2};
                }

                @media(max-width: 575px) {
                    margin-top : 0;
                }
            }
        }

        @media(max-width: 767px) {
            padding: 30px 0 40px;
        }
    }
	
    .blog-details-page {
		.blog-img-box {
			float: left;
			width: 33.33%;
		}
		
		.blog-img {
			width: 220px;
			height: 140px;
		}
	
		.blog-texto-centro {
			text-align: center;
		}
		
        .blog-details-area {
            padding : 70px 0;
            .blog-details-box {
                .blog-details-banner {
                    img {
                        border-radius: 5px;
                        margin-bottom: 20px;
                    }
                }

                .heading {
                    h4 {
                        color : ${colors.black1};
                        font-weight: 600;
                        line-height: 35px;
                        margin-bottom: 25px;

                        @media(max-width: 575px) {
                            font-size: 20px;
                        }
                    }
                }

                .blog-auth_date {
                    margin-bottom: 15px;
                    .author-img {
                        margin-right : 20px;
                        a {
                            img {
                                max-width: 40px;
                                border-radius: 50%;
                                margin-right: 10px;
                                margin-top: -9px;
                            }
                        }
                        p {
                            a {
                                font-size: 14px;
                                color: ${colors.green};
                                font-weight: 500;
                                &:hover {
                                    color: ${colors.black1};
                                }
                            }
                        }
                    }

                    .post-date,
                    .post-category,
                    .post-comment {
                        margin-right: 20px;
                        p {
                            font-size : 14px;
                            color: ${colors.text2};
                            font-weight: 500;
                            i {
                                font-size : 20px;
                                color: ${colors.green};
                                vertical-align: top;
                            }

                            a {
                                font-size : 14px;
                                color: ${colors.text2};
                                font-weight: 500;
                                &:hover {
                                    color: ${colors.green};
                                }
                            }
                        }

                        @media(max-width: 767px) {
                            margin-right: 8px;
                        }
                    }

                    .post-category,
                    .post-comment {
                        @media(max-width: 767px) {
                            display: none;
                        }
                    }
                }

                .blog-details-desc {
					text-align:justify;
                    margin-bottom: 45px;
                    p {
                        font-size: 15px;
                        color: ${colors.text2};
                        line-height: 25px;
                        span {
                            background : ${colors.border1};
                            color: ${colors.black2};
                            display : block;
                            margin: 35px 0;
                            padding: 22px 28px;
                            border-radius: 5px;
                            position: relative;
                            i {
                                position: absolute;
                                top: -20px;
                                left: 50%;
                                margin-left: -20px;
                                font-size : 30px;
                                color : #ffffff;
                                background : ${colors.gr_bg};
                                width: 42px;
                                height: 42px;
                                text-align: center;
                                border-radius: 50%;
                                padding-top: 7px;
                            }
                        }
                    }
                    ul {
                        margin-top : 30px;
                        li {
                            font-size: 14px;
                            color: ${colors.text3};
                            line-height: 25px;
                            margin-bottom: 15px;
                            i {
                                float: left;
                                color: ${colors.green};
                                border: 1px solid ${colors.border3};
                                width: 35px;
                                height: 35px;
                                border-radius: 50%;
                                text-align: center;
                                padding-top: 9px;
                                margin-top: 8px;
                                margin-right: 15px;
                            }
                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                    }
                }

                .blog-tag_share {
                    margin-bottom: 45px;
                    .blog-tag {
                        ul.tags {
                            li {
                                color : ${colors.text3};
                                a {
                                    font-size: 14px;
                                    color: ${colors.text2};
                                    &:hover {
                                        color: ${colors.green};
                                    }
                                }
                                &:first-child {
                                    font-size: 15px;
                                    color: ${colors.black1};
                                    font-weight: 500;
                                }
                            }
                        }

                        @media(max-width: 767px) {
                            margin-bottom: 20px;
                        }
                    }
                    .blog-share {
                        ul.social {
                            li {
                                a {
                                    text-align: center;
                                    position  : relative;
                                    height    : 18px;
                                    display   : inline-block;

                                    &:before {
                                        content           : "";
                                        position          : absolute;
                                        border-width      : 9px 17px;
                                        border-style      : solid;
                                        border-top-color  : transparent;
                                        border-right-color: transparent;
                                        border-left-color : transparent;
                                        top               : -18px;
                                        left              : 0;
                                        z-index           : 1;
                                        transition : all 0.2s ease;
                                    }

                                    &:after {
                                        content            : "";
                                        position           : absolute;
                                        border-width       : 9px 17px;
                                        border-style       : solid;
                                        border-right-color : transparent;
                                        border-bottom-color: transparent;
                                        border-left-color  : transparent;
                                        bottom             : -18px;
                                        left               : 0;
                                        z-index            : 1;
                                        transition : all 0.2s ease;
                                    }

                                    i {
                                        font-size: 14px;
                                        color    : #ffffff;
                                        width    : 34px;
                                    }

                                    &:hover {
                                        background-color: ${colors.green} !important;

                                        &:before {
                                            border-bottom-color: ${colors.green} !important;
                                        }

                                        &:after {
                                            border-top-color: ${colors.green} !important;
                                        }
                                    }
                                }

                                &:nth-child(1) {
                                    font-size: 15px;
                                    color: ${colors.black1};
                                    font-weight: 500;
                                }

                                &:nth-child(2) {
                                    a {
                                        background-color: #4267B2;

                                        &:before {
                                            border-bottom-color: #4267B2;
                                        }

                                        &:after {
                                            border-top-color: #4267B2;
                                        }
                                    }
                                }

                                &:nth-child(3) {
                                    a {
                                        background-color: #1DA1F2;

                                        &:before {
                                            border-bottom-color: #1DA1F2;
                                        }

                                        &:after {
                                            border-top-color: #1DA1F2;
                                        }
                                    }
                                }

                                &:nth-child(4) {
                                    a {
                                        background-color: #2867B2;

                                        &:before {
                                            border-bottom-color: #2867B2;
                                        }

                                        &:after {
                                            border-top-color: #2867B2;
                                        }
                                    }
                                }

                                &:nth-child(5) {
                                    a {
                                        background-color: #DD1343;

                                        &:before {
                                            border-bottom-color: #DD1343;
                                        }

                                        &:after {
                                            border-top-color: #DD1343;
                                        }
                                    }
                                }

                                &:nth-child(6) {
                                    a {
                                        background-color: #ea4c89;

                                        &:before {
                                            border-bottom-color: #ea4c89;
                                        }

                                        &:after {
                                            border-top-color: #ea4c89;
                                        }
                                    }
                                }
                            }
                        }

                        @media(max-width: 767px) {
                            margin-bottom: 20px;
                        }
                    }

                    @media(max-width: 767px) {
                        margin-bottom: 30px;
                        display: unset !Important;
                    }
                }

                .blog-comments {
                    margin-bottom: 45px;
                    h5 {
                        color: ${colors.black2};
                        font-weight: 600;
                        padding-bottom: 10px;
                        margin-bottom: 35px;
                        position: relative;
                        &:before {
                            position: absolute;
                            content: "";
                            background: ${colors.green};
                            width: 50px;
                            height: 2px;
                            bottom: 0;
                            left: 0;
                        }
                    }
                    .comment-box {
                        border-bottom: 1px solid ${colors.border1};
                        padding-bottom: 20px;
                        margin-bottom: 25px;
                        .comment-image {
                            img {
                                max-width : 80px;
                                border-radius : 5px;
                                margin-right : 20px;
                            }
                        }
                        .comment-content {
                            .content-title {
                                .comment-writer {
                                    h6 {
                                        color: ${colors.black2};
                                        font-weight: 600;
                                        margin-bottom : 10px;
                                    }
                                    p {
                                        font-size : 12px;
                                        color: ${colors.text3};
                                        margin-bottom: 5px;
                                    }
                                }
                                .reply-btn {
                                    button {
                                        font-size : 14px;
                                        color: ${colors.green};
                                        background : transparent;
                                        border : 1px solid ${colors.border3};
                                        font-weight: 500;
                                        border-radius: 25px;
                                        padding: 4px 12px 3px;
                                        margin-top : 3px;
                                        i {
                                            font-size: 17px;
                                            vertical-align: text-top;
                                        }
                                        &:hover {
                                            color : #ffffff;
                                            background : ${colors.gr_bg};
                                            border-color : ${colors.green};
                                        }
                                    }
                                }
                            }
                            .comment-desc {
                                p {
                                    font-size: 14px;
                                    color: ${colors.text2};
                                    line-height: 25px;
                                }
                            }
                        }
                        &:last-child {
                            border-bottom : none;
                            padding-bottom : 0;
                            margin-bottom : 0;
                        }
                    }
                }

                .blog-comment-form {
                    form {
                        @media(max-width: 575px) {
                            margin-bottom: 30px;
                        }
                    }
                }
            }

            @media(max-width: 767px) {
                padding: 35px 0;
            }

            @media(max-width: 575px) {
                padding-bottom: 0;
            }
        }
    }
`;