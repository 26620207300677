import React, { Component } from 'react';
//import Datas from '../../data/event/details.json';
//import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import Timer from 'react-compound-timer';
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
//import PopularCourse from './../courses/components/PopularCourse';
//import CourseTag from './../courses/components/CourseTag';
import Footer from '../../components/Footer';
import { Styles } from './styles/eventDetails.js';

class EventDetails extends Component {
	
	//Construtor para listar eventos
	constructor(props) {
		super(props);
		this.state=({
			db: [],
		});
		this.detalhesEvento(this.props.match.params.idEvento);
	}
	
	detalhesEvento(idEvento) {
			fetch("http://www.gpefed.com.br/src/api/?acao=detalhesEvento&idEvento="+idEvento)
			.then((response)=>response.json())
			.then((responseJson)=>
			{
				this.setState({
					db: responseJson
				});
				//console.log(this.state.db);
			})
	}
	
    render() {
        return (
            <Styles>
                {/* Main Wrapper */}
                <div className="main-wrapper event-details-page">

                    {/* Header 2 */}
                    <HeaderTwo />

                    {/* Breadcroumb */}
                    <BreadcrumbBox title="Eventos" />

                    {/* Event Details Area */}
                    <section className="event-details-area">
                        <Container>
                            <Row>
                                <Col lg="9" md="8" sm="12">
								{
									this.state.db.map((data) => (
										<div className="event-details-content">
											<div className="heading">
												<h4>{data.titulo}</h4>
											</div>
											<div className="event-icon">
												<ul className="list-unstyled list-inline">
													<li className="list-inline-item"><i className="las la-calendar"></i>{data.data}</li>
													<li className="list-inline-item"><i className="las la-clock"></i>{data.horario}</li>
													<li className="list-inline-item"><i className="las la-map-marker"></i>{data.local}</li>
													<li className="list-inline-item"><i className="las la-copy"></i>{data.assunto}</li>
												</ul>
											</div>
											<div className="event-details-banner">
												<img src={process.env.PUBLIC_URL + `/assets/images/${data.fotoGrande}`} alt="" className="img-fluid" />
											</div>
											<div className="event-details-overview">
												<h5>Descrição do Evento</h5>
												{
												data.descricao.map((dataDescricao) => (
													<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{dataDescricao.descricao}<br /><br /></p>
												))
												}
												
											</div>
											<div className="event-details-speaker">
											{/*<h5>Palestrantes</h5>*/}
												<Row>
													{
														data.palestrantes.map((dataPalestrantes, i) => (
															<Col lg="3" md="6" sm="6" key={i}>
																<div className="event-speaker-item">
																	<img src={process.env.PUBLIC_URL + `/assets/images/${dataPalestrantes.foto}`} alt="" className="img-fluid" />
																	<div className="img-content text-center">
																		<h6>{dataPalestrantes.nome}</h6>
																	</div>
																</div>
															</Col>
														))
													}
												</Row>
											</div>
											{/*
											<div className="pre-nxt-event">
												<Row>
													<Col md="6">
														<div className="next-prev-item prev-event d-flex">
															<div className="prev-img">
																<img src={process.env.PUBLIC_URL + `/assets/images/gallery-03.jpg`} alt="" />
															</div>
															<div className="prev-text">
																<p><Link to={process.env.PUBLIC_URL + "/event-details"}>Lorem, ipsum dolor sit amet consectetur adipisicing elit Perferendis.</Link></p>
																<span> March 27, 2020</span>
															</div>
														</div>
													</Col>
													<Col md="6">
														<div className="next-prev-item next-event d-flex flex-row-reverse text-right">
															<div className="next-img">
																<img src={process.env.PUBLIC_URL + `/assets/images/gallery-06.jpg`} alt="" />
															</div>
															<div className="next-text">
																<p><Link to={process.env.PUBLIC_URL + "/event-details"}>Lorem, ipsum dolor sit amet consectetur adipisicing elit Perferendis.</Link></p>
																<span> March 27, 2020</span>
															</div>
														</div>
													</Col>
												</Row>
											</div>
											*/}
										</div>
									))
								}
                                </Col>

                                <Col lg="3" md="4" sm="12">
								{
									this.state.db.map((data) => (
                                    <div className="event-details-sidebar">
                                        <Row>
                                            <Col md="12">
                                                <div className="event-sidebar-info">
                                                    <div className="event-sidebar-timer text-center">
                                                        <Timer initialTime={data.time} direction="backward">
                                                            <p><Timer.Days /><span>Dias</span></p>
                                                            <p><Timer.Hours /><span>Horas</span></p>
                                                            <p><Timer.Minutes /><span>Minutos</span></p>
                                                        </Timer>
                                                    </div>
                                                    <ul className="list-unstyled event-info-list">
                                                        <li>Data: <span>{data.data}</span></li>
                                                        <li>Hora: <span>{data.horario}</span></li>
                                                        <li>Local: <span>{data.local}</span></li>
                                                        <li>Mediador(a): <span>{data.organizador}</span></li>
                                                        <li>Website: <span><a href={data.website} target="_blank" rel="noopener noreferrer">{data.websiteNome}</a></span></li>
                                                    </ul>
													{/* <button type="button" className="buy-btn">Buy Ticket</button>*/}
                                                </div>
                                            </Col>
                                            <Col md="12">
												{/*<PopularCourse />*/}
                                            </Col>
                                            <Col md="12">
											{/*<CourseTag />*/}
                                            </Col>
                                        </Row>
                                    </div>
									))
								}
                                </Col>
                            </Row>
                        </Container>
                    </section>

                    {/* Footer 2 */}
                    <Footer />

                </div>
            </Styles>
        )
    }
}

export default EventDetails