import React, { Component } from 'react';
import { StylesCategory } from '../styles/courseCategory.js';

class CourseCategory extends Component {

    render() {
        return (
            <StylesCategory>
                {/* Course Tag */}
                <div className="course-category">
                    <h5>Áreas de Atuação</h5>
                    <ul className="category-item list-unstyled">
                        <li className="check-btn">
                            <label htmlFor="cat1"><input type="checkbox" id="cat1" className="check-box" />Ensino de Ciências<span>(25)</span></label>
                        </li>
                        <li className="check-btn">
                            <label htmlFor="cat2"><input type="checkbox" id="cat2" className="check-box" />Formação Docente<span>(11)</span></label>
                        </li>
                        <li className="check-btn">
                            <label htmlFor="cat3"><input type="checkbox" id="cat3" className="check-box" />EJA<span>(17)</span></label>
                        </li>
                        <li className="check-btn">
                            <label htmlFor="cat4"><input type="checkbox" id="cat4" className="check-box" />Ensino de Matemática<span>(23)</span></label>
                        </li>
                        <li className="check-btn">
                            <label htmlFor="cat5"><input type="checkbox" id="cat5" className="check-box" />Educação Indígena<span>(19)</span></label>
                        </li>
                        <li className="check-btn">
                            <label htmlFor="cat5"><input type="checkbox" id="cat5" className="check-box" />Educação no Campo<span>(19)</span></label>
                        </li>
                        <li className="check-btn">
                            <label htmlFor="cat5"><input type="checkbox" id="cat5" className="check-box" />Educação Ribeirinha<span>(19)</span></label>
                        </li>
                        <li className="check-btn">
                            <label htmlFor="cat5"><input type="checkbox" id="cat5" className="check-box" />Educação Ambiental<span>(19)</span></label>
                        </li>
                    </ul>
                </div>
            </StylesCategory>
        )
    }
}

export default CourseCategory
