import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Styles } from '../styles/courseTag.js';

class CourseTag extends Component {
    render() {
        return (
            <Styles>
                {/* Course Tag */}
                <div className="course-tag">
                    <h5>Projetos Tag</h5>
                    <div className="tag-box">
                        <Link to={process.env.PUBLIC_URL + "/"}>Matemática</Link>
                        <Link to={process.env.PUBLIC_URL + "/"}>Indígena</Link>
                        <Link to={process.env.PUBLIC_URL + "/"}>Ribeirinha</Link>
                        <Link to={process.env.PUBLIC_URL + "/"}>EJA</Link>
                        <Link to={process.env.PUBLIC_URL + "/"}>Ciências</Link>
                        <Link to={process.env.PUBLIC_URL + "/"}>Biologia</Link>
                        <Link to={process.env.PUBLIC_URL + "/"}>Química</Link>
                        <Link to={process.env.PUBLIC_URL + "/"}>Física</Link>
                    </div>
                </div>
            </Styles>
        )
    }
}

export default CourseTag
