import React, { Component } from 'react';
import { Container, Row, Col, Tab, Nav } from 'react-bootstrap';
import { Styles } from "./styles/tabBox.js";

class TabBox extends Component {

    render() {
        return (
            <Styles>
                {/* Tab Box Area */}
                <section className="tab-section">
                    <Container>
                        <Tab.Container defaultActiveKey="why">
                            <Row>
                                <Col lg="3" md="4">
                                    <Nav className="flex-column">
                                        <Nav.Item>
                                            <Nav.Link eventKey="why"><i className="las la-arrow-right"></i> Linha de Pesquisa 1</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="mission"><i className="las la-arrow-right"></i> Linha de Pesquisa 2</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="vision"><i className="las la-arrow-right"></i> Linha de Pesquisa 3</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="ranking"><i className="las la-arrow-right"></i> Linha de Pesquisa 4</Nav.Link>
                                        </Nav.Item>
                                        {/*<Nav.Item>
                                            <Nav.Link eventKey="research"><i className="las la-arrow-right"></i> Linha de Pesquisa 5</Nav.Link>
                                        </Nav.Item>*/}
                                    </Nav>
                                </Col>
                                <Col lg="9" md="8">
                                    <Tab.Content>
                                        <Tab.Pane eventKey="why">
                                            <h4 className="tab-title">Fundamentos, Currículos e Programas em Educação:</h4>
                                            <p className="tab-desc">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;O objetivo desta linha é desenvolver estudos e investigações sobre a cultura, o conhecimento, a formação humana e o debate da educação à luz de teorias que indagam as contradições da sociedade contemporânea. Problematizando a educação a partir do ponto de vista histórico, filosófico, sociológico e psicológico, preservando as particularidades desses campos do saber. Visa aprofundar conhecimentos sobre as concepções teóricas de currículo e programas em educação em conformidade com a Base Nacional Comum Curricular-BNCC.</p>
                                            {/*<ul className="list-unstyled check-list">
                                                <li><i className="fa fa-check"></i>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere.</li>
                                                <li><i className="fa fa-check"></i>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere.</li>
                                                <li><i className="fa fa-check"></i>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere.</li>
                                            </ul>*/}
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="mission">
                                            <h4 className="tab-title">Formação docente e desenvolvimento profissional: </h4>
                                            <p className="tab-desc">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;O objetivo desta linha de pesquisa é desenvolver pesquisas que tenham por objeto a ação de ensinar/educar e suas implicações na formação de professores e suas políticas; na produção de saberes e conhecimentos; nas práticas de memórias e histórias; no trabalho e na profissionalização docente. Compreendendo o desenvolvimento profissional docente como um contínuo, são focos de interesse os estudos sobre os currículos das licenciaturas, e as reflexões sobre o estágio supervisionado e a prática pedagógica como lócus de conhecimento, além dos diferentes modelos/formatos de formação continuada voltados aos professores da educação básica.</p>
                                            {/*<ul className="list-unstyled check-list">
                                                <li><i className="fa fa-check"></i>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere.</li>
                                                <li><i className="fa fa-check"></i>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere.</li>
                                                <li><i className="fa fa-check"></i>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere.</li>
                                            </ul>*/}
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="vision">
                                            <h4 className="tab-title">Educação, meio-ambiente e identidades étnico-culturais no Contexto Amazônico: </h4>
                                            <p className="tab-desc">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;O objetivo desta linha de pesquisa é investigar as especificidades da região amazônica e as diferentes concepções de educação neste contexto. São focos de interesse dessa linha de pesquisa os fundamentos epistemológicos, concepções, abordagens, diretrizes e práticas voltados a educação escolar indígena, a educação no campo e a educação ribeirinha. </p>
                                            {/*<ul className="list-unstyled check-list">
                                                <li><i className="fa fa-check"></i>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere.</li>
                                                <li><i className="fa fa-check"></i>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere.</li>
                                                <li><i className="fa fa-check"></i>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere.</li>
                                            </ul>*/}
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="ranking">
                                            <h4 className="tab-title">Ensino de Ciências e Matemática: </h4>
                                            <p className="tab-desc">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;O objetivo desta linha de pesquisa é a discussão da produção científica local, quer em termos de ideário (pesquisas  de estado da arte), quer de práticas pedagógicas (epistemológica/metodológica), em quaisquer dos níveis de ensino. Integram a proposta desta linha, estudos sobre a epistemologia da Ciência, da prática e da formação reflexiva docente, perfil didático e profissionalização de professores em ciências e matemática. Contempla, ainda, estudos sobre as tendências atuais de formação que possibilitem intervenção em contextos de ensino/educação, sob o entendimento da prática como instância epistemológica da construção de conhecimentos para 
a docência.
</p>
											{/*<ul className="list-unstyled check-list">
                                                <li><i className="fa fa-check"></i>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere.</li>
                                                <li><i className="fa fa-check"></i>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere.</li>
                                                <li><i className="fa fa-check"></i>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere.</li>
                                            </ul>*/}
                                        </Tab.Pane>
                                        {/*<Tab.Pane eventKey="research">
                                            <h4 className="tab-title">Our Research</h4>
                                            <p className="tab-desc">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere a nisi fuga rem quas molestias, eveniet minima molestiae. Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ea, recusandae? Assumenda, error. Quam dicta iusto saepe. Odit minus voluptas, fuga ipsum quia debitis totam, tempore laudantium quasi dicta dolorem deleniti.</p>
                                            <ul className="list-unstyled check-list">
                                                <li><i className="fa fa-check"></i>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere.</li>
                                                <li><i className="fa fa-check"></i>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere.</li>
                                                <li><i className="fa fa-check"></i>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere.</li>
                                            </ul>
                                        </Tab.Pane>*/}
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                    </Container>
                </section>
            </Styles>
        )
    }
}

export default TabBox
