import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Dropdown } from 'react-bootstrap';
//import Search from './common/Search';
//import Sidebar from './common/Sidebar';
//import StickyMenu from './common/StickyMenu';
import MobileMenu from './common/MobileMenu';
import { Styles } from "./styles/headerTwo.js";

class HeaderTwo extends Component {
	
	constructor() {
		super();
		this.state=({
			dbMenuPesquisadores: []
		});
		
		this.exibirMenuPesquisadores();
	}

	exibirMenuPesquisadores() {
			fetch("http://www.gpefed.com.br/src/api/?acao=menuPesquisadores")
			.then((response)=>response.json())
			.then((responseJson)=>
			{
				this.setState({
					dbMenuPesquisadores: responseJson
				});
				//console.log(this.state.db);
			})
	}
	
    render() {
        return (
            <Styles>
                {/* Topbar 2 */}
                <section className="top-bar2">
                    <Container>
                        <Row>
                            <Col lg="7" md="9">
                                <div className="bar-left">
                                    <ul className="list-unstyled list-inline" key="menu1">
                                        <li className="list-inline-item" key="menu2"><i className="las la-envelope"></i>gpefed.icet@gmail.com</li>
                                        <li className="list-inline-item" key="menu3"><i className="las la-map-marker"></i>Itacoatiara / Amazonas
                                    </li>
                                    </ul>
                                </div>
                            </Col>
                            <Col lg="5" md="3">
                                <div className="bar-right d-flex justify-content-end">
                                    <ul className="list-unstyled list-inline bar-social" key="menu4">
										<li className="list-inline-item"><a href="https://www.youtube.com/channel/UCt5h4a5cakmkojW5EaFJNqQ" target="_blank" rel="noopener noreferrer" key="menu5"><i className="fab fa-youtube"></i></a></li>
                                        {/*<li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-facebook-f"></i></a></li>*/}
										{/*<li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-instagram"></i></a></li>*/}
                                    </ul>
                                    <ul className="list-unstyled list-inline bar-lang" key="menu6">
                                        <li className="list-inline-item" key="menu7">
                                            <Dropdown>
                                                <Dropdown.Toggle><img src={process.env.PUBLIC_URL + "/assets/images/bra.png"} alt="" />Brasil</Dropdown.Toggle>
                                            </Dropdown>
                                        </li>
                                    </ul>
                                    <ul className="list-unstyled list-inline sidebar-button" key="menu8">
                                        <li className="list-inline-item nav-item side-box">
											{/*<Sidebar />*/}
                                        </li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>

                {/* Logo Area 2 */}
                <section className="logo-area2">
                    <Container>
                        <Row>
                            <Col md="3">
                                <div className="logo">
                                    <Link to={process.env.PUBLIC_URL + "/"}><img src={process.env.PUBLIC_URL + "/assets/images/logo_171_41.png"} alt="" /></Link>
                                </div>
                            </Col>
                            <Col md="9">
                                <div className="menu-box d-flex justify-content-end">
                                    <ul className="nav menu-nav" key="menu9">
										<li className="nav-item dropdown active">
											<Link className="nav-link" to={process.env.PUBLIC_URL + "/"}>Início</Link>
										</li>
										<li className="nav-item dropdown active" key="menu10">
											<Link className="nav-link" to={process.env.PUBLIC_URL + "/about"}>Quem Somos</Link>
										</li>
										<li className="nav-item dropdown" key="menu11">
											<Link className="nav-link dropdown-toggle" data-toggle="dropdown" to="">Pesquisadores <i className="las la-angle-down"></i></Link>
											<ul className="dropdown list-unstyled" key="menu12">
												{
												this.state.dbMenuPesquisadores.map((dataMenuPesquisadores, i) => (
												<li className="nav-item" key={i}><Link className="nav-link" to={process.env.PUBLIC_URL + `/instructor/${dataMenuPesquisadores.id}/${dataMenuPesquisadores.nome}/`}>{dataMenuPesquisadores.nome}</Link></li>
												))
												}
											</ul>
										</li>
										<li className="nav-item dropdown active" key="menu13">
											<Link className="nav-link" to={process.env.PUBLIC_URL + "/course-list/1"}>Projetos</Link>
										</li>
										<li className="nav-item" key="menu14">
											<Link className="nav-link" to={process.env.PUBLIC_URL + "/events/1"} data-toggle="dropdown">Eventos</Link>
										</li>
										<li className="nav-item" key="menu15">
											<Link className="nav-link" to={process.env.PUBLIC_URL + "/blog-grid/1"} data-toggle="dropdown">Blog</Link>
										</li>
										<li className="nav-item" key="menu16">
											<Link className="nav-link" to={process.env.PUBLIC_URL + "/gallery/1"} data-toggle="dropdown">Galeria</Link>
										</li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>

                {/* Sticky Menu */}
				{/*<StickyMenu />*/}
				
                {/* Mobile Menu */}
                <MobileMenu />
            </Styles>
        )
    }
}

export default HeaderTwo
