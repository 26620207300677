import React, { Component } from 'react';
import { Container, Row, Col, Tab, Nav } from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
//import ReviewForm from './components/ReviewForm';
//import PopularCourse from './components/PopularCourse';
//import CourseTag from './components/CourseTag';
import Footer from '../../components/Footer';
import { Styles } from './styles/course.js';

class CourseDetails extends Component {
	//Construtor para buscar dados do pesquisador
	constructor(props) {
		super(props);
		this.state=({
			dbProjetoPesquisadores: [], 
			dbPesquisadorQualificacao: []
		});
		
		this.detalhesPesquisador();
	}
	
	detalhesPesquisador() {
		fetch("http://www.gpefed.com.br/src/api/?acao=listarProjetosPesquisadores&idProjeto=8")
		.then((response)=>response.json())
		.then((responseJson)=>
		{
			
			this.setState({
				dbProjetoPesquisadores: responseJson
			});
		})
		
	}

	render() {
    return (
        <div className="main-wrapper course-details-page" >

            {/* Header 2 */}
            < HeaderTwo />

            {/* Breadcroumb */}
            < BreadcrumbBox title="Projetos" />

            <Styles>
                {/* Course Details */}
                <section className="course-details-area">
                    <Container>
                        <Row>
                            <Col lg="9" md="8" sm="12">
                                <div className="course-details-top">
                                    <div className="heading">
                                        <h4>Uma agenda de pesquisa para a EJA: Análise de conteúdo de livros didáticos para a alfabetização de jovens e adultos em Itacoatiara-AM.</h4>
                                    </div>
                                    <div className="course-top-overview">
                                        <div className="d-flex overviews">
                                            <div className="author">
                                                <img src={process.env.PUBLIC_URL + `/assets/images/fabricio_valentim_280_280.jpg`} alt="" />
                                                <div className="author-name">
                                                    <h6>Autor(a)</h6>
                                                    <p>Fabrício Valentim</p>
                                                </div>
                                            </div>
                                            {/*<div className="category">
                                                <h6>Categoria</h6>
                                                <p>????</p>
                                            </div>*/}
                                        </div>
                                    </div>
                                    <div className="course-details-banner">
                                        <img src={process.env.PUBLIC_URL + `/assets/images/projeto_08_1920_1161.jpg`} alt="" className="img-fluid" />
                                    </div>
                                    <div className="course-tab-list">
                                        <Tab.Container defaultActiveKey="overview">
                                            <Nav className="flex-column">
                                                <Nav.Item>
                                                    <Nav.Link eventKey="overview">Resumo</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="curriculum">Objetivos</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="instructor">Equipe</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="review">Fomento</Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                            <Tab.Content>
                                                <Tab.Pane eventKey="overview" className="overview-tab">
                                                    <div className="course-desc">
                                                        <h5>Resumo</h5>
                                                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A presente pesquisa integra uma proposta mais ampla denominada “Uma agenda de Pesquisa para a EJA”, que reúne três pesquisas de membros do Grupo de Pesquisa em Educação, Formação e Ensino para a diversidade (em processo de aprovação pela UFAM) com foco em três dimensões distintas, mas correlacionadas: Formação e atuação do docente, trajetórias de vida e de escolarização dos jovens e adultos que frequentam o EJA e análise de livros didáticos voltados para essa modalidade. Partimos da ideia de Zeichner (2009) que ressalta a importância de uma abordagem multidisciplinar e multimetodológica para investigar problemas referentes a formação e prática docente.</p>
                                                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Nesta perspectiva, vale destacar que, até o momento, não foram realizadas muitas análises de livros didáticos para alfabetização emancipadora de jovens e adultos na literatura internacional. Com base nas lacunas de pesquisa identificadas e nas recomendações de Wade (1993) - que propõem aos pesquisadores de livros didáticos da área de Estudos Sociais descobrir os contextos ideológicos que moldaram a estrutura e o conteúdo dos livros didáticos - elaboramos as seguintes questões norteadoras de pesquisa: quais são os critérios para identificar e analisar o conteúdo de livros didáticos que visam a alfabetização emancipadora de jovens e adultos no contexto educacional pós-colonial? E quais são os contextos ideológicos que moldaram a estrutura e o conteúdo desses livros didáticos em questão?</p>
														<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Esta pesquisa enfoca o estudo da noção de emancipação em livros didáticos adotados pela rede pública de ensino no município de Itacoatiara para a alfabetização de jovens e adultos no contexto da educação brasileira e amazônica. A amostra da pesquisa será composta pelos livros didáticos adotados pelas secretarias de educação do municipio nos últimos cinco anos. Esta pesquisa será orientada pela metodologia de análise do conteúdo de livros didáticos (Chu, 2017b; Holsti, 1969; Wade, 1993) e pela literatura sobre Educação para a Cidadania, uma vez que o objetivo principal será de avaliar a solidez de um modelo conceitual de alfabetização emancipadora por meio de três etapas metodológicas para analisar o conteúdo de livros didáticos favoráveis ao desenvolvimento desse tipo de alfabetização.</p>
														<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Além disso, esse objetivo foi dividido em três objetivos específicos: i. Identificar e descrever as dimensões ideológicas, cognitivas e cívicas da alfabetização nos livros didáticos da educação de jovens e adultos da rede pública de ensino de Itacoatiara-AM, no âmbito de uma perspectiva de modelagem conceitual e operacionalizar essas dimensões nos critérios de análise para a adequação dos livros didáticos; ii. Testar e avaliar a consistência dos critérios na análise do potencial emancipador dos livros didáticos de alfabetização utilizados na modalidade de educação de jovens e adultos na rede pública local; e iii. Identificar e avaliar os critérios que moldaram a estrutura e o conteúdo dos livros didáticos na educação de jovens e adultos do município em questão e se são favoráveis à alfabetização emancipadora.</p>
														<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Nessa perspectiva, a presente pesquisa está âncorada em estudos da Educação para a Cidadania e literatura de análise de conteúdo de livros didáticos, principalmente nas observações e sugestões de Wade (1993) e Chu (2017b) sobre como melhorar a qualidade da análise qualitativa do conteúdo dos livros de estudos sociais. Tendo em vista que, as investigações sobre educação para a cidadania e sobre a análise de conteúdo de livros didáticos permitirão identificar e analisar os elementos essenciais da alfabetização emancipadora de adultos sob uma perspectiva da Educação para a Cidadania (de tradição teórico-crítica), nos permitimos adotar algumas categorias de análise relacionadas aos conceitos desses estudos. Portanto, é a partir desses conceitos e postulados que projetamos a metodologia para responder aos nossos objetivos. Dessa maneira, a integração com as demais pesquisas voltadas a EJA no contexto amazônico permitirá um conhecimento profundo da temática no município de Itacoatiara, passível de ser generalizado para contextos semelhantes, que indique linhas de pesquisa promissoras e forneça conhecimentos para subsidiar o desenho de políticas públicas para essa modalidade educacional.</p>
                                                    </div>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="curriculum" className="overview-tab">
                                                    <div className="course-desc">
                                                        <h5>Objetivo Geral</h5>
                                                    </div>
                                                    <div className="course-feature">
                                                        <ul className="list-unstyled">
                                                            <li><i className="las la-arrow-right"></i>Avaliar a solidez de um modelo conceitual de alfabetização emancipadora para jovens e adultos por meio de três etapas metodológicas para analisar o conteúdo de livros didáticos favoráveis ao desenvolvimento desse tipo de alfabetização.</li>
                                                        </ul>
                                                    </div>
                                                    <div className="course-learn">
                                                        <h5>Objetivos Específicos</h5>
                                                        <ul className="list-unstyled">
                                                            <li><i className="fa fa-check"></i>Identificar e descrever as dimensões ideológicas, cognitivas e cívicas da alfabetização nos livros didáticos da educação de jovens e adultos da rede pública de ensino de Itacoatiara-AM no âmbito de uma perspectiva de modelagem conceitual e operacionalizar essas dimensões nos critérios de análise para a adequação dos livros didáticos;</li>
                                                            <li><i className="fa fa-check"></i>Testar e avaliar a consistência dos critérios na análise do potencial emancipador dos livros didáticos de alfabetização utilizados na modalidade de educação de jovens e adultos na rede pública local;</li>
															<li><i className="fa fa-check"></i>Identificar e avaliar os critérios que moldaram a estrutura e o conteúdo dos livros didáticos na educação de jovens e adultos do município de Itacoatiara-AM e se são favoráveis à alfabetização emancipadora.</li>
                                                        </ul>
                                                    </div>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="instructor" className="instructor-tab">
														{
														this.state.dbProjetoPesquisadores.map((pesquisador) => (
														<div className="instructor-item">
															<Row>
																<Col md="4">
																	<div className="instructor-img">
																		<img src={process.env.PUBLIC_URL + `/assets/images/${pesquisador.fotoPequena}`} alt="" className="img-fluid" />
																	</div>
																</Col>
																<Col md="8">
																	<div className="instructor-content">
																		<div className="instructor-box">
																			<div className="top-content d-flex justify-content-between">
																				<div className="instructor-name">
																					<h6>{pesquisador.nomeCompleto}</h6>
																					<p>{pesquisador.legenda}</p>
																				</div>
																				<div className="instructor-social">
																					<ul className="social list-unstyled list-inline">
																						<li className="list-inline-item">
																							<a href={pesquisador.orcid} target="_blank" rel="noopener noreferrer">
																								<img src={process.env.PUBLIC_URL + `/assets/images/iconID_48_48.jpg`} alt="Lattes" />
																							</a>
																						</li>
																						<li className="list-inline-item">
																							<a href={pesquisador.lattes} target="_blank" rel="noopener noreferrer">
																								<img src={process.env.PUBLIC_URL + `/assets/images/iconLATTES_48_48.jpg`} alt="Lattes" />
																							</a>
																						</li>
																					</ul>
																				</div>
																			</div>
																			<div className="instructor-desk">
																				<p>{pesquisador.qualificacao}</p>
																			</div>
																		</div>
																	</div>
																</Col>
															</Row>														
														</div>
														))
														}
                                                </Tab.Pane>
												<Tab.Pane eventKey="review" className="overview-tab">
                                                    <div className="course-desc">
                                                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A presente pesquisa foi aprovada na Chamada EDITAL N. 003/2020 - PAINTER</p>
                                                    </div>
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </Tab.Container>
                                    </div>
                                </div>
                            </Col>
                            <Col lg="3" md="4" sm="12">
								{/*<div className="single-details-sidbar">
                                    <Row>
                                        <Col md="12">
                                            <div className="course-details-feature">
                                                <h5 className="title">Detalhs do Projeto</h5>
                                                <ul className="list-unstyled feature-list">
                                                    <li><i className="las la-calendar"></i> Início Date: <span>????</span></li>
                                                    <li><i className="las la-clock"></i> Duração: <span>????</span></li>
                                                    <li><i className="las la-graduation-cap"></i> Estudo: <span>????</span></li>
                                                    <li><i className="las la-book"></i> Palestrantes: <span>???</span></li>
                                                    <li><i className="las la-bookmark"></i> Inscritos: <span>236</span></li>
                                                </ul>
                                                {/*<button type="button" className="enroll-btn">Enroll Course</button>*/}
													{/*</div>
                                        </Col>
                                    </Row>
                                </div>
								*/}

                            </Col>
                        </Row>
                    </Container>
                </section>
            </Styles>

            {/* Footer 2 */}
            <Footer />

        </div >
    )
}
}
export default CourseDetails