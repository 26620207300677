import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Dropdown } from 'react-bootstrap';
//import Search from './common/Search';
//import Sidebar from './common/Sidebar';
//import StickyMenu from './common/StickyMenu';
import MobileMenu from './common/MobileMenu';
import { Styles } from "./styles/header.js";

class Header extends Component {
	
	constructor() {
		super();
		this.state=({
			dbMenuPesquisadores: []
		});
		
		this.exibirMenuPesquisadores();
	}
	
	exibirMenuPesquisadores() {
			fetch("http://www.gpefed.com.br/src/api/?acao=menuPesquisadores")
			.then((response)=>response.json())
			.then((responseJson)=>
			{
				this.setState({
					dbMenuPesquisadores: responseJson
				});
				//console.log(this.state.db);
			})
	}
	
    render() {
        return (
            <Styles>
                {/* Topbar */}
                <section className="top-bar">
                    <Container>
                        <Row>
                            <Col lg="6" md="5">
                                <div className="bar-left">
                                    <ul className="list-unstyled list-inline" key="submenu1">
                                        <li className="list-inline-item"  key="menu1"><i className="las la-map-marker"></i>Itacoatiara / Amazonas</li>
                                        {/* <li className="list-inline-item"><Link to={process.env.PUBLIC_URL + "/faq"}>Tira Dúvidas</Link></li>*/}
                                    </ul>
                                </div>
                            </Col>
                            <Col lg="6" md="7">
                                <div className="bar-right d-flex justify-content-end">
                                    <ul className="list-unstyled list-inline bar-social" key="submenu2">
										{/*<li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-facebook-f"></i></a></li>*/}
                                        <li className="list-inline-item"><a href="https://www.youtube.com/channel/UCt5h4a5cakmkojW5EaFJNqQ" target="_blank" rel="noopener noreferrer" key="menu2"><i className="fab fa-youtube"></i></a></li>
                                    </ul>
                                    <ul className="list-unstyled list-inline bar-lang" key="submenu3">
                                        <li className="list-inline-item" key="menu3">
                                            <Dropdown>
                                                <Dropdown.Toggle as="a"><img src={process.env.PUBLIC_URL + "/assets/images/bra.png"} alt="" />Brasil</Dropdown.Toggle>
                                            </Dropdown>
                                        </li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>

                {/* Logo Area */}
                <section className="logo-area">
                    <Container>
                        <Row>
                            <Col md="3">
                                <div className="logo">
                                    <Link to={process.env.PUBLIC_URL + "/"}><img src={process.env.PUBLIC_URL + "/assets/images/logo_171_41.png"} alt="" /></Link>
                                </div>
                            </Col>
                            <Col md="9">
                                <div className="logo-contact-box d-flex justify-content-end">
                                    <div className="emcontact-box d-flex">
                                        <div className="box-icon">
                                            <i className="flaticon-envelope"></i>
                                        </div>
                                        <div className="box-content">
                                            <p>Entre em contato</p>
                                            <span>gpefed.icet@gmail.com</span>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>

                {/* Navbar */}
                <section className="main-menu">
                    <Container>
                        <Row>
                            <Col md="12">
                                <div className="main-menu-box">
                                    <div className="menu-box d-flex justify-content-between">
                                        <ul className="nav menu-nav"  key="submenu4">
                                            <li className="nav-item dropdown active" key="menu4">
                                                <Link className="nav-link" to={process.env.PUBLIC_URL + "/"}>Início</Link>
                                            </li>
                                            <li className="nav-item dropdown active" key="menu5">
                                                <Link className="nav-link" to={process.env.PUBLIC_URL + "/about"}>Quem Somos</Link>
                                            </li>
                                            <li className="nav-item dropdown" key="menu6">
                                                <Link className="nav-link dropdown-toggle" to="" data-toggle="dropdown">Pesquisadores <i className="las la-angle-down"></i></Link>
                                                <ul className="dropdown list-unstyled" key="submenu5">
													{
													this.state.dbMenuPesquisadores.map((dataMenuPesquisadores, i) => (
                                                    <li className="nav-item" key={i}><Link className="nav-link" to={process.env.PUBLIC_URL + `/instructor/${dataMenuPesquisadores.id}/${dataMenuPesquisadores.nome}/`}>{dataMenuPesquisadores.nome}</Link></li>
													))
													}
                                                </ul>
                                            </li>
                                            <li className="nav-item dropdown active" key="menu7">
												<Link className="nav-link" to={process.env.PUBLIC_URL + "/course-list/1"}>Projetos</Link>
                                            </li>
                                            {/* <li className="nav-item dropdown">
                                                <Link className="nav-link dropdown-toggle" to={process.env.PUBLIC_URL + "/"} data-toggle="dropdown">Courses <i className="las la-angle-down"></i></Link>
                                                <ul className="dropdown list-unstyled">
                                                    <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/course-grid"}>Course Grid</Link></li>
                                                    <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/course-list"}>Course List</Link></li>
                                                    <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/course-details"}>Course Details</Link></li>
                                                </ul>
                                            </li> */}
                                            <li className="nav-item" key="menu8">
                                                <Link className="nav-link" to={process.env.PUBLIC_URL + "/events/1"} data-toggle="dropdown">Eventos</Link>
                                            </li>
                                            <li className="nav-item" key="menu9">
                                                <Link className="nav-link" to={process.env.PUBLIC_URL + "/blog-grid/1"} data-toggle="dropdown">Blog</Link>
                                            </li>
                                            <li className="nav-item" key="menu10">
                                                <Link className="nav-link" to={process.env.PUBLIC_URL + "/gallery/1"} data-toggle="dropdown">Galeria</Link>
                                            </li>
                                            {/*<li className="nav-item dropdown">
                                                <Link className="nav-link dropdown-toggle" to={process.env.PUBLIC_URL + "/"} data-toggle="dropdown">Event <i className="las la-angle-down"></i></Link>
                                                <ul className="dropdown list-unstyled">
                                                    <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/events"}>Events</Link></li>
                                                    <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/event-details"}>Event Details</Link></li>
                                                </ul>
                                            </li>
                                            <li className="nav-item dropdown">
                                                <Link className="nav-link dropdown-toggle" to={process.env.PUBLIC_URL + "/"} data-toggle="dropdown">Blog <i className="las la-angle-down"></i></Link>
                                                <ul className="dropdown list-unstyled">
                                                    <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/blog-classic"}>Blog Classic</Link></li>
                                                    <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/blog-grid"}>Blog Grid</Link></li>
                                                    <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/blog-details"}>Blog Details</Link></li>
                                                </ul>
                                            </li>
                                            <li className="nav-item dropdown">
                                                <Link className="nav-link dropdown-toggle" to={process.env.PUBLIC_URL + "/"} data-toggle="dropdown">Shop <i className="las la-angle-down"></i></Link>
                                                <ul className="dropdown list-unstyled">
                                                    <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/products"}>Products</Link></li>
                                                    <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/product-details"}>Product Details</Link></li>
                                                    <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/cart"}>Cart</Link></li>
                                                </ul>
                                            </li>*/}
                                        </ul>
                                        <ul className="nav search-cart-bar" key="menu15">
										{/*<li className="nav-item search-box">
                                                <Search />
                                            </li>
                                            <li className="nav-item cart-box">
                                                <Link to={process.env.PUBLIC_URL + "/cart"} className="nav-link nav-cart">
                                                    <i className="las la-shopping-bag"></i>
                                                </Link>
                                            </li>
                                            <li className="nav-item side-box">
                                                <Sidebar />
										</li>*/}
                                        </ul>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>

                {/* Sticky Menu */}
				{/* <StickyMenu />*/}

                {/* Mobile Menu */}
                <MobileMenu />
            </Styles>
        )
    }
}

export default Header
