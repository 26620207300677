import React, { Component } from 'react';
//import Datas from '../data/team/team-slider.json';
import { Container, Row, Col } from 'react-bootstrap';
import Swiper from 'react-id-swiper';
import { Styles } from "./styles/teamSlider.js";

class TeamSlider extends Component {
	
	constructor(props) {
		super(props);
		this.state=({
			dbTeam: []
		});
		this.listarPesquisadores('-1');
	}
	
	listarPesquisadores(idTipoPesquisador) {
			fetch("http://www.gpefed.com.br/src/api/?acao=listarPesquisadores&idTipoPesquisador="+idTipoPesquisador)
			.then((response)=>response.json())
			.then((responseJson)=>
			{
				this.setState({
					dbTeam: responseJson
				});
				console.log(this.state.dbTeam);
			})
	}
	
    render() {
        const settings = {
            slidesPerView: 1,
            loop: false,
			effect: 'slide',
			preloadImages: true, 
			updateOnImagesReady: true, 
            speed: 1000,
            autoplay: {
                delay: 3000,
                disableOnInteraction: false
            },
            spaceBetween: 30,
            watchSlidesVisibility: true,
            pagination: {
                el: '.slider-dot.text-center',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1
                },
                576: {
                    slidesPerView: 2
                },
                768: {
                    slidesPerView: 3
                },
                992: {
                    slidesPerView: 4
                }
            }
        };
		
		return (
            <Styles>
                {/* Team Slider */}
                <section className="team-member-area">
                    <Container>
                        <Row>
                            <Col md="12">
                                <div className="sec-title text-center">
                                    <h4>Equipe</h4>
                                </div>
                            </Col>
                            <Col md="12" className="team-slider">
                                <Swiper {...settings}>
                                    {
                                        this.state.dbTeam.map((pesquisador, i) => (
                                            <div className="team-item" key={i}>
                                                <img src={process.env.PUBLIC_URL + `/assets/images/${pesquisador.fotoPequena}`} alt="" className="img-fluid" />
                                                <div className="img-content text-center">
													<h5>{pesquisador.nome}</h5>
                                                    <p>{pesquisador.legenda}</p>
                                                    <ul className="list-unstyled list-inline">
														<li className="list-inline-item">
															<div className="img">
																<a href={pesquisador.orcid} target="_blank" rel="noopener noreferrer">
																	<img src={process.env.PUBLIC_URL + `/assets/images/iconID_24_24.jpg`} alt="Orcid" />
																</a>
															</div>
														</li>
														<li className="list-inline-item">
															<div className="img">
																<a href={pesquisador.lattes} target="_blank" rel="noopener noreferrer">
																	<img src={process.env.PUBLIC_URL + `/assets/images/iconLATTES_24_24.jpg`} alt="Lattes" />
																</a>
															</div>
														</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </Swiper>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Styles>
        )
    }
}

export default TeamSlider