import React, { Component } from 'react';
import Datas from '../data/footer/footer.json';
//import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import BackToTop from './common/BackToTop';
import { Styles } from "./styles/footerOne.js";

class Footer extends Component {
    render() {
        return (
            <Styles>
                {/* Footer Area */}
                <footer className="footer1" style={{ backgroundImage: `url(assets/images/${process.env.PUBLIC_URL + Datas.backgroundImage})` }}>
                    <Container>
                        <Row>
                            <Col md="4">
                                <div className="footer-logo-info">
                                    <img src={process.env.PUBLIC_URL + "/assets/images/logo_171_41.png"} alt="" className="img-fluid" />
                                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;O grupo de pesquisa "Educação, Formação e Ensino para a diversidade" (GPEFED) tem como objetivos fortalecer e qualificar as produções científicas da região norte de forma crítica, inclusiva e participativa.</p>
                                    <ul className="list-unstyled">
                                        <li><i className="las la-graduation-cap"></i>Instituto de Ciências Exatas e Tecnologia/ Universidade Federal do Amazonas.</li>
										<li><i className="las la-map-marker"></i>Av. Nossa Senhora do Rosário, 3863 - Bairro Tiradentes, Itacoatiara/AM.</li>
                                        <li><i className="las la-envelope"></i>gpefed.icet@gmail.com</li>
                                        {/* <li><i className="las la-phone"></i>+1 908 875 7678</li> */}
                                    </ul>
                                </div>
                            </Col>
                            <Col md="4">
                                <div className="f-links">
                                    <h5>Links Úteis</h5>
                                    <ul className="list-unstyled">
                                        <li><a href="https://ufam.edu.br/" target="_blank" rel="noopener noreferrer"><i className="las la-angle-right"></i>UFAM</a></li>
                                        <li><a href="https://icet.ufam.edu.br/" target="_blank" rel="noopener noreferrer"><i className="las la-angle-right"></i>ICET</a></li>
                                        <li><a href="http://www.cnpq.br" target="_blank" rel="noopener noreferrer"><i className="las la-angle-right"></i>CNPQ</a></li>
                                        <li><a href="http://www.fapeam.am.gov.br/" target="_blank" rel="noopener noreferrer"><i className="las la-angle-right"></i>FAPEAM</a></li>
                                        <li><a href="http://www.anped.org.br" target="_blank" rel="noopener noreferrer"><i className="las la-angle-right"></i>ANPED</a></li>
                                    </ul>
                                    <ul className="list-unstyled">
                                        <li><a href="http://www.capes.gov.br" target="_blank" rel="noopener noreferrer"><i className="las la-angle-right"></i>CAPES</a></li>
                                        <li><a href="http://www.inep.gov.br" target="_blank" rel="noopener noreferrer"><i className="las la-angle-right"></i>INEP</a></li>
										<li><a href="http://www.mec.gov.br" target="_blank" rel="noopener noreferrer"><i className="las la-angle-right"></i>MEC</a></li>
										<li><a href="https://www-periodicos-capes-gov-br.ezl.periodicos.capes.gov.br/index.php" target="_blank" rel="noopener noreferrer"><i className="las la-angle-right"></i>Periódicos Capes</a></li>
										<li><a href="https://bdtd.ibict.br/vufind/" target="_blank" rel="noopener noreferrer"><i className="las la-angle-right"></i>Biblioteca</a></li>
                                    </ul>
                                </div>
                            </Col>
                            <Col md="4">
								<div className="footer-logo-info">
                                    <div className="post-box d-flex">
                                        <div className="post-img">
                                            <a href="https://icet.ufam.edu.br/" target="_blank" rel="noopener noreferrer"><img src={process.env.PUBLIC_URL + "/assets/images/logo_ufamicet_171_41.jpg"} alt="" className="img-fluid" /></a>
                                        </div>
                                    </div>
                                    <div className="post-box d-flex">
                                        <div className="post-img">
                                            <a href="https://www.gov.br/cnpq/pt-br" target="_blank" rel="noopener noreferrer"><img src={process.env.PUBLIC_URL + "/assets/images/logo_cnpq_171_41.jpg"} alt="" className="img-fluid" /></a>
                                        </div>
                                    </div>
                                    <div className="post-box d-flex">
                                        <div className="post-img">
                                            <a href="http://www.fapeam.am.gov.br/" target="_blank" rel="noopener noreferrer"><img src={process.env.PUBLIC_URL + "/assets/images/logo_fapeam_171_41.jpg"} alt="" className="img-fluid" /></a>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </footer>

                {/* Copyright Area */}
                <section className="copyright-area">
                    <Container>
                        <Row>
                            <Col md="8">
                                <div className="copy-text">
                                    <p>Copyright &copy; 2020 | Desenvolvido com <i className="las la-heart"></i> por <a href={process.env.PUBLIC_URL + "/"} target="_blank" rel="noopener noreferrer">GPEFED</a>. Este site foi financiado com recursos do CNPq.</p>
                                </div>
                            </Col>
                            <Col md="4" className="text-right">
                                <ul className="social list-unstyled list-inline">
									{/*<li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-facebook-f"></i></a></li>*/}
                                    {/* <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-twitter"></i></a></li> */}
                                    {/* <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-linkedin-in"></i></a></li> */}
                                    <li className="list-inline-item"><a href="https://www.youtube.com/channel/UCt5h4a5cakmkojW5EaFJNqQ"><i className="fab fa-youtube"></i></a></li>
                                    {/* <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-dribbble"></i></a></li> */}
                                </ul>
                            </Col>
                        </Row>
                    </Container>

                    {/* Back To Top */}
                    <BackToTop/>
                </section>
            </Styles>
        )
    }
}

export default Footer
