import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
//import BlogSidebar from './components/BlogSidebar';
//import CommentForm from './components/CommentForm';
import Footer from '../../components/Footer';
import { Styles } from './styles/blogDetails.js';

class BlogDetails extends Component {
	//Construtor para listar blog
	constructor(props) {
		super(props);
		this.state=({
			db: [],
		});
		this.detalhesBlog();
	}
	
	
	detalhesBlog(idBlog) {
			fetch("http://www.gpefed.com.br/src/api/?acao=detalhesBlog&idBlog=1")
			.then((response)=>response.json())
			.then((responseJson)=>
			{
				this.setState({
					db: responseJson
				});
				//console.log(this.state.db);
			})
	}
	
    render() {
        return (
            <Styles>
                {/* Main Wrapper */}
                <div className="main-wrapper blog-details-page">

                    {/* Header 2 */}
                    <HeaderTwo />

                    {/* Breadcroumb */}
                    <BreadcrumbBox title="Blog" />

                    {/* Blog Details */}
                    <section className="blog-details-area">
                        <Container>
                            <Row>
							{
								this.state.db.map((data, i) => (
                                <Col lg="9" md="8" sm="7">
                                    <div className="blog-details-box">
                                        <div className="blog-details-banner">
                                            <img src={process.env.PUBLIC_URL + `/assets/images/${data.foto}`} alt="" className="img-fluid" />
                                        </div>
                                        <div className="heading">
                                            <h4>{data.titulo}</h4>
                                        </div>
                                        <div className="blog-auth_date d-flex">
                                            <div className="author-img d-flex">
                                                <Link to={process.env.PUBLIC_URL + "/"}><img src={process.env.PUBLIC_URL + `/assets/images/${data.fotoAutor}`} alt="" /></Link>
                                                <p><Link to={process.env.PUBLIC_URL + "/"}>{data.autor}</Link></p>
                                            </div>
                                            <div className="post-date">
												<p><i className="las la-calendar"></i>{data.data}</p>
                                            </div>
                                            <div className="post-category">
												{/*<p><Link to={process.env.PUBLIC_URL + "/"}><i className="las la-bookmark"></i> Web Design</Link></p>*/}
                                            </div>
                                            <div className="post-comment">
												{/*<p><Link to={process.env.PUBLIC_URL + "/"}><i className="las la-comment"></i> (23)</Link></p>*/}
                                            </div>
                                        </div>
                                        <div className="blog-details-desc">
                                            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{data.resumo}</p>
											
											<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Iniciei minha graduação em outubro de 2013, com 29 anos, no curso de Licenciatura em Ciências: Química e Biologia da Universidade Federal do Amazonas – UFAM/ICET. Sou natural do município de Itacoatiara, Estado do Amazonas, distante 266KM da capital Manaus pela rodovia AM010. Toda a minha escolarização até o nível superior foi regularmente cursada em escola pública, mas a faculdade representou um grande desafio pois, além das dificuldades convencionais de qualquer acadêmico, enfrentei o desafio da perda gradativa e total da visão durante a graduação. </p>
											
											<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;No ano de 2008, ao perceber a visão turva com vultos no olho esquerdo e subnormal no direito procurei um oftalmologista e fui diagnosticado com retinose pigmentar, trata-se de uma doença silenciosa e irreversível que afeta a retina em um processo de perda visual total gradativa, causada por lesões e cicatrizes retinianas intratáveis. Apesar desse diagnóstico, no ano de 2012 participei do Exame Nacional do Ensino Médio-ENEM e ingressei por ampla concorrência via SISU para cursar a dupla licenciatura em ciências. O resultado positivo nesse processo só foi possível, graças ao incentivo da minha única irmã que também, alcançou classificação para o mesmo curso, tendo como principal meta acompanhar-me nessa etapa importante dos meus estudos. </p>
											
											<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Minha condição de saúde, no momento do ingresso na Universidade, era de cego funcional, dispondo apenas de resquício visual no melhor olho que me permitia somente realizar leitura de materiais impressos e anotações (através de...) e mobilidade por meio do recurso da autonomia assistida, presente no apoio irrestrito dos meus familiares. </p>
											
											<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Em 2016, estando no 6º período e perto de finalizar a matéria “Instrumentação para o ensino da química”, me foi proposto o desafio de criar um material didático que atendesse a educação inclusiva no processo de ensino/aprendizagem do aluno com deficiência visual, dentro do tema alfabetização científica. O objetivo da disciplina de Instrumentação é</p>
											
											<p><span><i className="las la-quote-right"></i>“Capacitar o estudante para realização de aulas práticas de química no ensino médio. Desenvolver capacidade de discussão, de elucidação e de análise das dificuldades de química no ensino médio que permita propor e aplicar modelos tradicionais e alternativos que possam contribuir para um melhor processo ensino-aprendizagem. (Projeto Pedagógico Curricular do Curso de Licenciatura em Ciências: Química e Biologia).”</span></p>
											
                                            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;No escopo desta disciplina, me foi proposto o desafio de criar um material didático que atendesse a educação inclusiva no processo de ensino-aprendizagem do aluno com deficiência visual, dentro do tema alfabetização científica. Optei pelo conteúdo “Diagrama de Linus Paulling – Distribuição Eletrônica” que consiste num modelo que auxilia na configuração dos elétrons, dos átomos e dos íons através de subníveis de energia. Este método é usado na química para determinar algumas características dos átomos.</p>
											
											<br />
											<div class="blog-img-box"><img src={process.env.PUBLIC_URL + `/assets/images/blog_01_img_01.jpg`} class="blog-img" alt="Imagem da ferramenta" /></div>
											<div class="blog-img-box"><img src={process.env.PUBLIC_URL + `/assets/images/blog_01_img_02.jpg`} class="blog-img" alt="Imagem da ferramenta" /></div>
											<div class="blog-img-box"><img src={process.env.PUBLIC_URL + `/assets/images/blog_01_img_03.jpg`} class="blog-img" alt="Imagem da ferramenta" /></div>                                            
											<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
											
											<div class="blog-texto-centro">Imagens da ferramenta<br />Créditos: Rafael Amazonas<br /><br /></div>
											
											<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Uma vez definido o conteúdo a ser trabalhado, busquei analisar quais as condições de acessibilidades educacionais mais compatíveis com as identidades sensoriais próprias dos alunos sem visão total, e conclui, como deficiente visual, que o melhor sentido é o tato, através dele é possível explorar e conhecer a diversidade de texturas, formas e relevos dos materiais e objetos existentes.</p>
                                            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A montagem do material didático contou com a ajuda de um colega de curso que realizou todo o trabalho de recorte e colagem dos materiais, com minha orientação e supervisão.</p>
											
											<br />
											<div class="blog-img-box"><img src={process.env.PUBLIC_URL + `/assets/images/blog_01_img_04.jpg`} class="blog-img" alt="Imagem da ferramenta" /></div>
											<div class="blog-img-box"><img src={process.env.PUBLIC_URL + `/assets/images/blog_01_img_05.jpg`} class="blog-img" alt="Imagem da ferramenta" /></div>
											<div class="blog-img-box"><img src={process.env.PUBLIC_URL + `/assets/images/blog_01_img_06.jpg`} class="blog-img" alt="Imagem da ferramenta" /></div>                                            
											<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
											
											<div class="blog-texto-centro">Imagens da ferramenta<br />Créditos: Rafael Amazonas<br /><br /></div>
											
                                            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Tive a oportunidade de “testar” o material desenvolvido durante o Estágio em Química, na sala de recursos de uma escola pública da rede estadual com o atendimento, de dois alunos cegos do 1º ano do ensino médio, ao longo do atendimento destes dois alunos, reavaliei e fiz adequações no material, principalmente para facilitar o transporte do material didático.</p>
											
                                            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Se você quiser conhecer um pouco mais deste material, leia nosso artigo “Construção de uma ferramenta tátil de química para o ensino e aprendizagem do Diagrama de Linus Paulling: Configuração eletrônica”, no e-book Educação Contemporânea - Volume 15 – Ensino Superior/ Organização: GONÇALVES, Maria Célia da Silva; JESUS, Bruna Guzman de – Belo Horizonte– MG: Poisson, 2021.</p>
											
                                            {/*<ul className="list-unstyled">
                                                <li><i className="fa fa-check"></i> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere Quae impedit eligendi perspiciatis animi maxime ab minus corporis omnis similique excepturi.</li>
                                                <li><i className="fa fa-check"></i> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere Quae impedit eligendi perspiciatis animi maxime ab minus corporis omnis similique excepturi.</li>
                                                <li><i className="fa fa-check"></i> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere Quae impedit eligendi perspiciatis animi maxime ab minus corporis omnis similique excepturi.</li>
                                            </ul>*/}
                                        </div>
                                        {/*<div className="blog-tag_share d-flex justify-content-between">
                                            <div className="blog-tag">
                                                <ul className="tags list-unstyled list-inline">
                                                    <li className="list-inline-item">Tags:</li>
                                                    <li className="list-inline-item"><Link to={process.env.PUBLIC_URL + "/"}>Html</Link>,</li>
                                                    <li className="list-inline-item"><Link to={process.env.PUBLIC_URL + "/"}>Design</Link>,</li>
                                                    <li className="list-inline-item"><Link to={process.env.PUBLIC_URL + "/"}>Develop</Link>,</li>
                                                    <li className="list-inline-item"><Link to={process.env.PUBLIC_URL + "/"}>React</Link></li>
                                                </ul>
                                            </div>
                                            <div className="blog-share">
                                                <ul className="social list-unstyled list-inline">
                                                    <li className="list-inline-item">Share:</li>
                                                    <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-facebook-f"></i></a></li>
                                                    <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-twitter"></i></a></li>
                                                    <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-linkedin-in"></i></a></li>
                                                    <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-youtube"></i></a></li>
                                                    <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-dribbble"></i></a></li>
                                                </ul>
                                            </div>
                                        </div>
										*/}
										{/*
                                        <div className="blog-comments">
                                            <h5>Comments (03)</h5>
                                            <div className="comment-box d-flex">
                                                <div className="comment-image">
                                                    <img src={process.env.PUBLIC_URL + `/assets/images/testimonial-2.jpg`} alt="" />
                                                </div>
                                                <div className="comment-content">
                                                    <div className="content-title d-flex justify-content-between">
                                                        <div className="comment-writer">
                                                            <h6>Mark Shadow</h6>
                                                            <p>Mar 26, 2020 | 06:30pm</p>
                                                        </div>
                                                        <div className="reply-btn">
                                                            <button type="button"><i className="las la-reply-all"></i> Reply</button>
                                                        </div>
                                                    </div>
                                                    <div className="comment-desc">
                                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto laborum quas placeat perspiciatis est, nisi expedita consectetur sit minus illum laudantium nostrum dolore odit asperiores quisquam ad enim iusto laborum quas placeat perspiciatis saepe.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="comment-box d-flex">
                                                <div className="comment-image">
                                                    <img src={process.env.PUBLIC_URL + `/assets/images/testimonial-1.jpg`} alt="" />
                                                </div>
                                                <div className="comment-content">
                                                    <div className="content-title d-flex justify-content-between">
                                                        <div className="comment-writer">
                                                            <h6>Katrin Kay</h6>
                                                            <p>Mar 26, 2020 | 06:30pm</p>
                                                        </div>
                                                        <div className="reply-btn">
                                                            <button type="button"><i className="las la-reply-all"></i> Reply</button>
                                                        </div>
                                                    </div>
                                                    <div className="comment-desc">
                                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto laborum quas placeat perspiciatis est, nisi expedita consectetur sit minus illum laudantium nostrum dolore odit asperiores quisquam ad enim iusto laborum quas placeat perspiciatis saepe.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="comment-box d-flex">
                                                <div className="comment-image">
                                                    <img src={process.env.PUBLIC_URL + `/assets/images/testimonial-2.jpg`} alt="" />
                                                </div>
                                                <div className="comment-content">
                                                    <div className="content-title d-flex justify-content-between">
                                                        <div className="comment-writer">
                                                            <h6>David Show</h6>
                                                            <p>Mar 26, 2020 | 06:30pm</p>
                                                        </div>
                                                        <div className="reply-btn">
                                                            <button type="button"><i className="las la-reply-all"></i> Reply</button>
                                                        </div>
                                                    </div>
                                                    <div className="comment-desc">
                                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto laborum quas placeat perspiciatis est, nisi expedita consectetur sit minus illum laudantium nostrum dolore odit asperiores quisquam ad enim iusto laborum quas placeat perspiciatis saepe.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <CommentForm />
										*/}
                                    </div>
                                </Col>
								))
							}
                                <Col lg="3" md="4" sm="5">
									{/*<BlogSidebar />*/}
                                </Col>
                            </Row>
                        </Container>
                    </section>

                    {/* Footer */}
                    <Footer />

                </div>
            </Styles>
        )
    }
}

export default BlogDetails