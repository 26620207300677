import React, { Component } from 'react';
import { Container, Row, Col, Tab, Nav } from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
//import ReviewForm from './components/ReviewForm';
//import PopularCourse from './components/PopularCourse';
//import CourseTag from './components/CourseTag';
import Footer from '../../components/Footer';
import { Styles } from './styles/course.js';

class CourseDetails extends Component {
	//Construtor para buscar dados do pesquisador
	constructor(props) {
		super(props);
		this.state=({
			dbProjetoPesquisadores: [], 
			dbPesquisadorQualificacao: []
		});
		
		this.detalhesPesquisador();
	}
	
	detalhesPesquisador() {
		fetch("http://www.gpefed.com.br/src/api/?acao=listarProjetosPesquisadores&idProjeto=6")
		.then((response)=>response.json())
		.then((responseJson)=>
		{
			
			this.setState({
				dbProjetoPesquisadores: responseJson
			});
		})
		
	}

	render() {
    return (
        <div className="main-wrapper course-details-page" >

            {/* Header 2 */}
            < HeaderTwo />

            {/* Breadcroumb */}
            < BreadcrumbBox title="Projetos" />

            <Styles>
                {/* Course Details */}
                <section className="course-details-area">
                    <Container>
                        <Row>
                            <Col lg="9" md="8" sm="12">
                                <div className="course-details-top">
                                    <div className="heading">
                                        <h4>Uma agenda de pesquisa para a EJA: Análise de conteúdo de livros didáticos para a alfabetização de jovens e adultos em Itacoatiara-Am</h4>
                                    </div>
                                    <div className="course-top-overview">
                                        <div className="d-flex overviews">
                                            <div className="author">
                                                <img src={process.env.PUBLIC_URL + `/assets/images/fabricio_valentim_280_280.jpg`} alt="" />
                                                <div className="author-name">
                                                    <h6>Autor(a)</h6>
                                                    <p>Fabrício Valentim</p>
                                                </div>
                                            </div>
                                            {/*<div className="category">
                                                <h6>Categoria</h6>
                                                <p>????</p>
                                            </div>*/}
                                        </div>
                                    </div>
                                    <div className="course-details-banner">
                                        <img src={process.env.PUBLIC_URL + `/assets/images/projeto_06_1920_1161.jpg`} alt="" className="img-fluid" />
                                    </div>
                                    <div className="course-tab-list">
                                        <Tab.Container defaultActiveKey="overview">
                                            <Nav className="flex-column">
                                                <Nav.Item>
                                                    <Nav.Link eventKey="overview">Resumo</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="curriculum">Objetivos</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="instructor">Equipe</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="review">Fomento</Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                            <Tab.Content>
                                                <Tab.Pane eventKey="overview" className="overview-tab">
                                                    <div className="course-desc">
                                                        <h5>Resumo</h5>
                                                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Este estudo tem por objetivo central avaliar a solidez de um modelo conceitual de alfabetização emancipadora para jovens e adultos por meio de três etapas metodológicas para analisar o conteúdo de livros didáticos favoráveis ao desenvolvimento desse tipo de alfabetização. A presente pesquisa é uma investigação documental qualitativa de livros didáticos impressos para alfabetização de jovens e adultos, em um contexto educacional brasileiro amazônico pós-colonial, e faz parte da tradição dos estudos de caso. Nesta pesquisa, serão utilizados os dados empíricos de uma amostra composta pelos livros didáticos utilizados no processo de alfabetização de jovens e adultos nos últimos 3 anos (2017, 2018 e 2019) pela rede pública de ensino de Itacoatiara-AM, note-se que a amostra não é aleatória e justifica-se a escolha com base na relevância de se analisar os materiais didáticos da EJA, já que não são objetos de estudo comuns na agenda de pesquisa nacional e internacional, conforme aponta a literatura da área de análise de livros didáticos de Estudos Sociais. Levando em consideração o objetivo geral deste estudo, a presente análise qualitativa de conteúdo dos livros didáticos será dividida em três etapas metodológicas. Portanto, é a partir desses conceitos e postulados que projetamos a metodologia para responder aos nossos objetivos. Esta investigação integra uma proposta mais ampla denominada “Uma agenda de Pesquisa para a EJA”, que reúne três pesquisas de membros do Grupo de Pesquisa em Educação, Formação e Ensino para a diversidade apresentadas em editais de financiamento da FAPEAM em 2020. Espera-se ao final do estudo contribuir com o desenvolvimento e aprimoramento de políticas públicas para a EJA no interior do Amazonas.</p>
														<br />
														<p>Vigência: 15/06/2020 a 31/06/2022.</p>
                                                    </div>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="curriculum" className="overview-tab">
                                                    <div className="course-desc">
                                                        <h5>Objetivo Geral</h5>
                                                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Avaliar a solidez de um modelo conceitual de alfabetização emancipadora para jovens e adultos por meio de três etapas metodológicas para analisar o conteúdo de livros didáticos favoráveis ao desenvolvimento desse tipo de alfabetização.</p>
                                                    </div>
													<div className="course-desc">
														<h5>Objetivos Específicos</h5>
													</div>
                                                    <div className="course-learn">
                                                        <ul className="list-unstyled">
                                                            <li><i className="fa fa-check"></i>Identificar e descrever as dimensões ideológicas, cognitivas e cívicas da alfabetização nos livros didáticos da educação de jovens e adultos da rede pública de ensino de Itacoatiara-AM no âmbito de uma perspectiva de modelagem conceitual e operacionalizar essas dimensões nos critérios de análise para a adequação dos livros didáticos; </li>
                                                            <li><i className="fa fa-check"></i>Testar e avaliar a consistência dos critérios na análise do potencial emancipador dos livros didáticos de alfabetização utilizados na modalidade de educação de jovens e adultos na rede pública local;</li>
                                                            <li><i className="fa fa-check"></i>Identificar e avaliar os critérios que moldaram a estrutura e o conteúdo dos livros didáticos na educação de jovens e adultos do município de Itacoatiara-AM e se são favoráveis à alfabetização emancipadora.</li>
                                                        </ul>
                                                    </div>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="instructor" className="instructor-tab">
														{
														this.state.dbProjetoPesquisadores.map((pesquisador) => (
														<div className="instructor-item">
															<Row>
																<Col md="4">
																	<div className="instructor-img">
																		<img src={process.env.PUBLIC_URL + `/assets/images/${pesquisador.fotoPequena}`} alt="" className="img-fluid" />
																	</div>
																</Col>
																<Col md="8">
																	<div className="instructor-content">
																		<div className="instructor-box">
																			<div className="top-content d-flex justify-content-between">
																				<div className="instructor-name">
																					<h6>{pesquisador.nomeCompleto}</h6>
																					<p>{pesquisador.legenda}</p>
																				</div>
																				<div className="instructor-social">
																					<ul className="social list-unstyled list-inline">
																						<li className="list-inline-item">
																							<a href={pesquisador.orcid} target="_blank" rel="noopener noreferrer">
																								<img src={process.env.PUBLIC_URL + `/assets/images/iconID_48_48.jpg`} alt="Lattes" />
																							</a>
																						</li>
																						<li className="list-inline-item">
																							<a href={pesquisador.lattes} target="_blank" rel="noopener noreferrer">
																								<img src={process.env.PUBLIC_URL + `/assets/images/iconLATTES_48_48.jpg`} alt="Lattes" />
																							</a>
																						</li>
																					</ul>
																				</div>
																			</div>
																			<div className="instructor-desk">
																				<p>{pesquisador.qualificacao}</p>
																			</div>
																		</div>
																	</div>
																</Col>
															</Row>														
														</div>
														))
														}
                                                </Tab.Pane>
												<Tab.Pane eventKey="review" className="overview-tab">
                                                    <div className="course-desc">
                                                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A presente pesquisa foi aprovada na Chamada/Edital 2020-2021 FAPEAM/PIBIC.</p>
                                                    </div>
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </Tab.Container>
                                    </div>
                                </div>
                            </Col>
                            <Col lg="3" md="4" sm="12">
								{/*<div className="single-details-sidbar">
                                    <Row>
                                        <Col md="12">
                                            <div className="course-details-feature">
                                                <h5 className="title">Detalhs do Projeto</h5>
                                                <ul className="list-unstyled feature-list">
                                                    <li><i className="las la-calendar"></i> Início Date: <span>????</span></li>
                                                    <li><i className="las la-clock"></i> Duração: <span>????</span></li>
                                                    <li><i className="las la-graduation-cap"></i> Estudo: <span>????</span></li>
                                                    <li><i className="las la-book"></i> Palestrantes: <span>???</span></li>
                                                    <li><i className="las la-bookmark"></i> Inscritos: <span>236</span></li>
                                                </ul>
                                                {/*<button type="button" className="enroll-btn">Enroll Course</button>*/}
													{/*</div>
                                        </Col>
                                    </Row>
                                </div>
								*/}
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Styles>

            {/* Footer 2 */}
            <Footer />

        </div >
    )
}
}
export default CourseDetails