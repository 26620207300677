import React, { Component } from 'react';
import { StylesSearch } from '../styles/courseSearch.js';

class CourseSearch extends Component {

    render() {
        return (
            <StylesSearch>
                {/* Course Search */}
                <div className="course-search">
                    <h5>Busca de Projetos</h5>
                    <form action="#">
                        <input type="text" name="search" placeholder="Busca" />
                        <button type="submit"><i className="las la-search"></i></button>
                    </form>
                </div>
            </StylesSearch>
        )
    }
}

export default CourseSearch
