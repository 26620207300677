import React, { Component, Fragment } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
//import CourseSidebar from './components/CourseSidebar';
//import CourseItemList from './components/CourseItemsList'; 
import Footer from '../../components/Footer';
import { StylesPaginacao } from "./styles/pagination.js";
//import { StylesSearch } from "./styles/courseSearch.js";
import { StylesCategory } from "./styles/courseCategory.js";
import { Styles } from './styles/course.js';


class CourseList extends Component {
	//Construtor para listar projetos
	constructor(props) {
		super(props);
		this.pgAnt = null;
		this.pgNov = null;
		this.state=({
			db: [],
			dbAreasAtuacao: [],
			dbPaginacao: []
		});
		this.paginacaoProjetos();
		this.listarAreasAtuacao();
	}
	
	listarProjetos(pagina) {
			fetch("http://www.gpefed.com.br/src/api/?acao=listarProjetos&pagina="+pagina)
			.then((response)=>response.json())
			.then((responseJson)=>
			{
				this.setState({
					db: responseJson
				});
				//console.log(this.state.db);
			})
	}
	
	listarAreasAtuacao(pagina) {
			fetch("http://www.gpefed.com.br/src/api/?acao=listarAreasAtuacao")
			.then((response)=>response.json())
			.then((responseJson)=>
			{
				this.setState({
					dbAreasAtuacao: responseJson
				});
				//console.log(this.state.dbAreasAtuacao);
			})
	}
	
	paginacaoProjetos() {
			fetch("http://www.gpefed.com.br/src/api/?acao=paginacaoProjetos")
			.then((response)=>response.json())
			.then((responseJsonPaginacao)=>
			{
				this.setState({
					dbPaginacao: responseJsonPaginacao
				});
				//console.log(this.state.dbPaginacao);
			})
	}
	
    render() {
		this.pgNov = this.props.match.params.pagina;
		if( this.pgAnt !== this.pgNov ) {
			this.listarProjetos(this.props.match.params.pagina);
			this.pgAnt = this.props.match.params.pagina;
		}
		
        return (
            <div className="main-wrapper course-page">

                {/* Header 2 */}
                <HeaderTwo />

                {/* Breadcroumb */}
                <BreadcrumbBox title="Projetos" />

                <Styles>
                    {/* Course Grid */}
                    <section className="course-list-area">
                        <Container>
                            <Row>
								{/* Course Sidebar/Category/TAG */}
                                <Col lg="3" md="4" sm="5">
                                    <div className="course-sidebar">
										<Row>
											<Col md="12">
												<StylesCategory>
													{/* Course Tag */}
													<div className="course-category">
														<h5>Áreas de Atuação</h5>
														<ul className="category-item list-unstyled" key="item1">
															{
																this.state.dbAreasAtuacao.map((areasAtuacao, i) => (
																	<li className="check-btn" key={i}>
																		<label htmlFor="cat1">{areasAtuacao.nome}<span>({areasAtuacao.totalProjetos})</span></label>
																	</li>
																))
															}
														</ul>
													</div>
												</StylesCategory>
											</Col>
											{ /*<Col md="12">
												<CoursePrice />
											</Col>
											<Col md="12">
												<PopularCourse />
											</Col>*/ }
											<Col md="12">
											{/*<CourseTag />*/}
											</Col>
										</Row>
									</div>
                                </Col>
                                <Col lg="9" md="8" sm="7">
                                    <div className="course-items2">
                                        <Row>
                                            <Fragment>
												{/* Course Item */}
												{
													this.state.db.map((data, i) => (
														<Col md="12" key={i}>
															<div className="course-item d-flex">
																<div className="course-image-box">
																	<div className="course-image" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/${data.fotoPequena})` }}>
																		<div className="author-img d-flex">
																			<div className="img">
																				<Link to={process.env.PUBLIC_URL + data.courseLink}>
																					<img src={process.env.PUBLIC_URL + `/assets/images/${data.fotoAutor}`} alt="" />
																				</Link>
																			</div>
																			<div className="title">
																				<p>{data.nomeAutor}</p>
																			</div>
																		</div>
																	</div>
																</div>
																<div className="course-content">
																	<h6 className="heading"><Link to={process.env.PUBLIC_URL+`/course-details_${data.id}`}>{data.titulo}</Link></h6>
																	<p className="desc">{data.descricao}</p>
																	<Link className="details-btn" to={process.env.PUBLIC_URL+`/course-details_${data.id}`}>Detalhes</Link>
																</div>
															</div>
														</Col>
													))
												}
												
												<Col md="12"  className="text-center">
													<StylesPaginacao>
														{/* Pagination */}
														<ul className="pagination-box list-unstyled list-inline" key="item4">
														{/*<li className="list-inline-item"><Link to={process.env.PUBLIC_URL + "/"}><i className="las la-angle-double-left"></i></Link></li>*/}
																{
																	this.state.dbPaginacao.map((dataPaginacao, i) => {
																		if ( String(dataPaginacao.pagina) === String(this.props.match.params.pagina) ) { 
																			return <li className="active list-inline-item" key={`a`+i}><Link to={process.env.PUBLIC_URL + `/course-list/${dataPaginacao.pagina}`}>{dataPaginacao.pagina}</Link></li>
																		} else {
																			return <li className="list-inline-item"key={`b`+i}><Link to={process.env.PUBLIC_URL + `/course-list/${dataPaginacao.pagina}`}>{dataPaginacao.pagina}</Link></li>
																		}
																	})
																}
															{/*<li className="list-inline-item"><Link to={process.env.PUBLIC_URL + "/"}>2</Link></li>
															<li className="active list-inline-item"><Link to={process.env.PUBLIC_URL + "/"}>3</Link></li>
															<li className="list-inline-item"><Link to={process.env.PUBLIC_URL + "/"}>...</Link></li>
															<li className="list-inline-item"><Link to={process.env.PUBLIC_URL + "/"}>13</Link></li>*/}
															{/*<li className="list-inline-item"><Link to={process.env.PUBLIC_URL + "/"}><i className="las la-angle-double-right"></i></Link></li>*/}
														</ul>
													</StylesPaginacao>
												</Col>
											</Fragment>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                </Styles>

                {/* Footer 2 */}
                <Footer />

            </div>
        )
    }
}

export default CourseList