import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import Datas from '../../data/blog/blog_3.json';
import ModalVideo from 'react-modal-video';
//import BlogSidebar from './components/BlogSidebar';
//import CommentForm from './components/CommentForm';
import Footer from '../../components/Footer';
import { Styles } from './styles/blogDetails.js';

class BlogDetails extends Component {
	//Construtor para listar blog
	constructor(props) {
		super(props);
		this.state=({
			db: [],
			isOpen: false
		});
		this.detalhesBlog();
		
        this.openModal = this.openModal.bind(this)
	}
	
    openModal() {
        this.setState({ isOpen: true })
    }
	
	detalhesBlog(idBlog) {
			fetch("http://www.gpefed.com.br/src/api/?acao=detalhesBlog&idBlog=3")
			.then((response)=>response.json())
			.then((responseJson)=>
			{
				this.setState({
					db: responseJson
				});
				//console.log(this.state.db);
			})
	}
	
    render() {
        return (
            <Styles>
                {/* Main Wrapper */}
                <div className="main-wrapper blog-details-page">

                    {/* Header 2 */}
                    <HeaderTwo />

                    {/* Breadcroumb */}
                    <BreadcrumbBox title="Blog" />

                    {/* Blog Details */}
                    <section className="blog-details-area">
                        <Container>
                            <Row>
							{
								this.state.db.map((data, i) => (
                                <Col lg="9" md="8" sm="7">
                                    <div className="blog-details-box">
                                        <div className="blog-details-banner">
                                            <img src={process.env.PUBLIC_URL + `/assets/images/${data.foto}`} alt="" className="img-fluid" />
                                        </div>
                                        <div className="heading">
                                            <h4>{data.titulo}</h4>
                                        </div>
                                        <div className="blog-auth_date d-flex">
                                            <div className="author-img d-flex">
                                                <Link to={process.env.PUBLIC_URL + "/"}><img src={process.env.PUBLIC_URL + `/assets/images/${data.fotoAutor}`} alt="" /></Link>
                                                <p><Link to={process.env.PUBLIC_URL + "/"}>{data.autor} da Silva&#185;</Link></p>
                                            </div>
                                            <div className="post-date">
												<p><i className="las la-calendar"></i>{data.data}</p>
                                            </div>
                                        </div>
                                        <div className="blog-details-desc">
                                            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{data.resumo}</p>
											<p>Assista no link: <a href="https://www.youtube.com/watch?v=9STITPairbc&t=7057s">https://www.youtube.com</a> ou clique abaixo.</p>
                                        </div>
                                    </div>
                                </Col>
								))
							}
                            </Row>
                        </Container>						
                    </section>					
					<section className="about-us">
						<Container>
							<Row>
								<Col md="6">
									{/*https://www.youtube.com/watch?v=9STITPairbc&t=7057s*/}
									<div className="about-image">
										<img src={process.env.PUBLIC_URL + `/assets/images/blog_03_1935_950.jpg`} className="main-img" alt="" />
										<img src={process.env.PUBLIC_URL + "/assets/images/pattern.png"} className="pattern-img" alt="" />
										<div className="video-player" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/fabricio_valentim_640_825.jpg)`}}>
											<ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='9STITPairbc' start="7057" onClose={() => this.setState({ isOpen: false })} />
											<button onClick={this.openModal} className="play-button"><i className="las la-play"></i></button>
										</div>
										<p><br /></p>
										<p><br /></p>
										<p><br /></p>
									</div>
								</Col>
							</Row>
						</Container>
					</section>
					<section className="about-us">
					</section>


                    {/* Footer */}
                    <Footer />

                </div>
            </Styles>
        )
    }
}

export default BlogDetails