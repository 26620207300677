import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ScrollToTop from './helper/ScrollToTop';
import { GlobalStyle } from "./components/common/styles/global.js";
import HomeOne from './HomeOne';
import HomeTwo from './HomeTwo';
import About from './pages/about/About';
import CourseGrid from './pages/courses/CourseGrid';
import CourseList from './pages/courses/CourseList';
import CourseDetails_1 from './pages/courses/CourseDetails_1';
import CourseDetails_2 from './pages/courses/CourseDetails_2';
import CourseDetails_3 from './pages/courses/CourseDetails_3';
import CourseDetails_4 from './pages/courses/CourseDetails_4';
import CourseDetails_5 from './pages/courses/CourseDetails_5';
import CourseDetails_6 from './pages/courses/CourseDetails_6';
import CourseDetails_7 from './pages/courses/CourseDetails_7';
import CourseDetails_8 from './pages/courses/CourseDetails_8';
import CourseDetails_9 from './pages/courses/CourseDetails_9';
import Instructor from './pages/instructor/Instructors';
import InstructorDetails from './pages/instructor/InstructorDetails';
import Gallery from './pages/gallery/Gallery';
import Events from './pages/events/Events';
import EventDetails from './pages/events/EventsDetails';
import Login from './pages/account/Login';
import Register from './pages/account/Register';
import Contact from './pages/contact/Contact';
import Faq from './pages/faq/Faq';
import PageNotFound from './pages/404/PageNotFound';
import ComingSoon from './pages/comingsoon/ComingSoon';
import BlogClassic from './pages/blog/BlogClassic';
import BlogGrid from './pages/blog/BlogGrid';
import BlogDetails_1 from './pages/blog/BlogDetails_1';
import BlogDetails_2 from './pages/blog/BlogDetails_2';
import BlogDetails_3 from './pages/blog/BlogDetails_3';
import Product from './pages/shop/Products';
import ProductDetails from './pages/shop/ProductDetails';
import Cart from './pages/shop/Cart';

function App() {

    return (
        <Router>
            <GlobalStyle />
            <ScrollToTop />
            <Switch>
                <Route exact path={`${process.env.PUBLIC_URL + "/"}`} component={HomeOne} />
                <Route path={`${process.env.PUBLIC_URL + "/home-two"}`} component={HomeTwo} />
                <Route path={`${process.env.PUBLIC_URL + "/about"}`} component={About} />
                <Route path={`${process.env.PUBLIC_URL + "/course-grid"}`} component={CourseGrid} />
                <Route path={`${process.env.PUBLIC_URL + "/course-list/:pagina"}`} component={CourseList} />
                <Route path={`${process.env.PUBLIC_URL + "/course-details_1/"}`} component={CourseDetails_1} />
				<Route path={`${process.env.PUBLIC_URL + "/course-details_2/"}`} component={CourseDetails_2} />
				<Route path={`${process.env.PUBLIC_URL + "/course-details_3/"}`} component={CourseDetails_3} />
				<Route path={`${process.env.PUBLIC_URL + "/course-details_4/"}`} component={CourseDetails_4} />
				<Route path={`${process.env.PUBLIC_URL + "/course-details_5/"}`} component={CourseDetails_5} />
				<Route path={`${process.env.PUBLIC_URL + "/course-details_6/"}`} component={CourseDetails_6} />
				<Route path={`${process.env.PUBLIC_URL + "/course-details_7/"}`} component={CourseDetails_7} />
				<Route path={`${process.env.PUBLIC_URL + "/course-details_8/"}`} component={CourseDetails_8} />
				<Route path={`${process.env.PUBLIC_URL + "/course-details_9/"}`} component={CourseDetails_9} />
                <Route path={`${process.env.PUBLIC_URL + "/instructor/:idTipoPesquisador/:page"}`} component={Instructor} />
                <Route path={`${process.env.PUBLIC_URL + "/instructor-details/:idPesquisador/:page"}`} component={InstructorDetails} />
                <Route path={`${process.env.PUBLIC_URL + "/gallery/:pagina"}`} component={Gallery} />
                <Route path={`${process.env.PUBLIC_URL + "/events/:pagina"}`} component={Events} />
                <Route path={`${process.env.PUBLIC_URL + "/event-details/:idEvento"}`} component={EventDetails} />
                <Route path={`${process.env.PUBLIC_URL + "/login"}`} component={Login} />
                <Route path={`${process.env.PUBLIC_URL + "/registration"}`} component={Register} />
                <Route path={`${process.env.PUBLIC_URL + "/contact"}`} component={Contact} />
                <Route path={`${process.env.PUBLIC_URL + "/faq"}`} component={Faq} />
                <Route path={`${process.env.PUBLIC_URL + "/404"}`} component={PageNotFound} />
                <Route path={`${process.env.PUBLIC_URL + "/coming-soon"}`} component={ComingSoon} />
                <Route path={`${process.env.PUBLIC_URL + "/blog-classic"}`} component={BlogClassic} />
                <Route path={`${process.env.PUBLIC_URL + "/blog-grid/:pagina"}`} component={BlogGrid} />
                <Route path={`${process.env.PUBLIC_URL + "/blog-details_1/"}`} component={BlogDetails_1} />
				<Route path={`${process.env.PUBLIC_URL + "/blog-details_2/"}`} component={BlogDetails_2} />
				<Route path={`${process.env.PUBLIC_URL + "/blog-details_3/"}`} component={BlogDetails_3} />
                <Route path={`${process.env.PUBLIC_URL + "/products"}`} component={Product} />
                <Route path={`${process.env.PUBLIC_URL + "/product-details"}`} component={ProductDetails} />
                <Route path={`${process.env.PUBLIC_URL + "/cart"}`} component={Cart} />
            </Switch>
        </Router>
    )
}

export default App;