import React, { Component } from 'react';
import { Container, Row, Col, Tab, Nav } from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
//import ReviewForm from './components/ReviewForm';
//import PopularCourse from './components/PopularCourse';
//import CourseTag from './components/CourseTag';
import Footer from '../../components/Footer';
import { Styles } from './styles/course.js';

class CourseDetails extends Component {
	//Construtor para buscar dados do pesquisador
	constructor(props) {
		super(props);
		this.state=({
			dbProjetoPesquisadores: [], 
			dbPesquisadorQualificacao: []
		});
		
		this.detalhesPesquisador();
	}
	
	detalhesPesquisador() {
		fetch("http://www.gpefed.com.br/src/api/?acao=listarProjetosPesquisadores&idProjeto=2")
		.then((response)=>response.json())
		.then((responseJson)=>
		{
			
			this.setState({
				dbProjetoPesquisadores: responseJson
			});
		})
		
	}

	render() {
    return (
        <div className="main-wrapper course-details-page" >

            {/* Header 2 */}
            < HeaderTwo />

            {/* Breadcroumb */}
            < BreadcrumbBox title="Projetos" />

            <Styles>
                {/* Course Details */}
                <section className="course-details-area">
                    <Container>
                        <Row>
                            <Col lg="9" md="8" sm="12">
                                <div className="course-details-top">
                                    <div className="heading">
                                        <h4>Uma Agenda de Pesquisa para a EJA: Análise da Formação, Metodologias e Estratégiasdos professores que atuam nessa modalidade no Município de Itacoatiara/Amazonas</h4>
                                    </div>
                                    <div className="course-top-overview">
                                        <div className="d-flex overviews">
                                            <div className="author">
                                                <img src={process.env.PUBLIC_URL + `/assets/images/nayana_teles_280_280.jpg`} alt="" />
                                                <div className="author-name">
                                                    <h6>Autor(a)</h6>
                                                    <p>Nayana Teles</p>
                                                </div>
                                            </div>
                                            {/*<div className="category">
                                                <h6>Categoria</h6>
                                                <p>????</p>
                                            </div>*/}
                                        </div>
                                    </div>
                                    <div className="course-details-banner">
                                        <img src={process.env.PUBLIC_URL + `/assets/images/projeto_02_1920_1161.jpg`} alt="" className="img-fluid" />
                                    </div>
                                    <div className="course-tab-list">
                                        <Tab.Container defaultActiveKey="overview">
                                            <Nav className="flex-column">
                                                <Nav.Item>
                                                    <Nav.Link eventKey="overview">Resumo</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="curriculum">Objetivos</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="instructor">Equipe</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="review">Fomento</Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                            <Tab.Content>
                                                <Tab.Pane eventKey="overview" className="overview-tab">
                                                    <div className="course-desc">
                                                        <h5>Resumo</h5>
                                                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A presente pesquisa integra uma proposta mais ampla denominada “Uma agenda de Pesquisa para a EJA”, que reúne três pesquisas de membros do Grupo de Pesquisa em Educação, Formação e Ensino para a Diversidade (GPEFED) com foco em três dimensões distintas, mas correlacionadas: Formação e atuação do docente, trajetórias de vida e de escolarização dos jovens e adultos que frequentam o EJA e análise de livros didáticos voltados para essa modalidade.</p>
														<br />
														<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Partimos da ideia de Zeichner (2009) que ressalta a importância de uma abordagem multidisciplinar e multimetodológica para investigar problemas referentes a formação e prática docente. </p>
														<br />
														<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Espera-se, ao final da pesquisa, conhecer a realidade escolar e os desafios da atuação profissional dos docentes da EJA de forma a orientar a elaboração de projetos de formação continuada de professores, em parceria com as Secretarias Municipal e Estadual de Educação, além de retroalimentar a formação inicial de docentes nas licenciaturas do ICET a partir da compreensão dos problemas concretos vivenciados por esses professores nas escolas, e assim orientar a construção de projetos pedagógicos curriculares que melhor qualifiquem os professores para o exercício da docência.</p>
														<br />
														<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Pretende-se com a participação dos docentes no Programa de Mentoria, oferecer suporte para a realização de atividades pedagógicas inovadoras e potencializadoras da aprendizagem dos alunos, não somente no âmbito da alfabetização e letramento, mas também no ensino de ciências e matemática.</p>
														<br />
														<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A integração dos dados das pesquisas que compõe a “Agenda de Pesquisa para a EJA” tem potencial para fornecer conhecimentos consistentes e capazes de subsidiar políticas públicas voltadas para essa modalidade, além de contribuir para o do campo de formação docente.</p>
														<br />
														<p>Vigência: 15/06/2020 a 30/08/2021</p>
                                                    </div>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="curriculum" className="overview-tab">
                                                    <div className="course-desc">
                                                        <h5>Objetivo Geral</h5>
                                                    </div>
                                                    <div className="course-feature">
                                                        <ul className="list-unstyled">
                                                            <li><i className="las la-arrow-right"></i>Analisar o perfil profissional dos professores que atuam na EJA, bem como os principais desafios que eles enfrentam no cotidiano da sala de aula e os recursos que utilizam para lidar com esses desafios, ajudando-os a formular alternativas para o seu enfrentamento.</li>
                                                        </ul>
                                                    </div>
                                                    <div className="course-learn">
                                                        <h5>Objetivos Específicos</h5>
                                                        <ul className="list-unstyled">
                                                            <li><i className="fa fa-check"></i>Caracterizar os professores que lecionam na EJA;<br /><br /></li>
                                                            <li><i className="fa fa-check"></i>Identificar quais estratégias e metodologias esses docentes utilizam para trabalhar com esse público;<br /><br /></li>
                                                            <li><i className="fa fa-check"></i>Compreender quais os principais desafios enfrentados por esses docentes para ensinar seus alunos e os recursos dos quais lançam mão para superá-los;</li>
                                                            <li><i className="fa fa-check"></i>Integrar os docentes que atuam com o ensino de ciências em um programa de Mentoria de Professores;<br /><br /></li>
															<li><i className="fa fa-check"></i>Analisar o impacto da participação no programa de Mentoria na prática destes docentes.</li>
                                                        </ul>
                                                    </div>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="instructor" className="instructor-tab">
														{
														this.state.dbProjetoPesquisadores.map((pesquisador) => (
														<div className="instructor-item">
															<Row>
																<Col md="4">
																	<div className="instructor-img">
																		<img src={process.env.PUBLIC_URL + `/assets/images/${pesquisador.fotoPequena}`} alt="" className="img-fluid" />
																	</div>
																</Col>
																<Col md="8">
																	<div className="instructor-content">
																		<div className="instructor-box">
																			<div className="top-content d-flex justify-content-between">
																				<div className="instructor-name">
																					<h6>{pesquisador.nomeCompleto}</h6>
																					<p>{pesquisador.legenda}</p>
																				</div>
																				<div className="instructor-social">
																					<ul className="social list-unstyled list-inline">
																						<li className="list-inline-item">
																							<a href={pesquisador.orcid} target="_blank" rel="noopener noreferrer">
																								<img src={process.env.PUBLIC_URL + `/assets/images/iconID_48_48.jpg`} alt="Lattes" />
																							</a>
																						</li>
																						<li className="list-inline-item">
																							<a href={pesquisador.lattes} target="_blank" rel="noopener noreferrer">
																								<img src={process.env.PUBLIC_URL + `/assets/images/iconLATTES_48_48.jpg`} alt="Lattes" />
																							</a>
																						</li>
																					</ul>
																				</div>
																			</div>
																			<div className="instructor-desk">
																				<p>{pesquisador.qualificacao}</p>
																			</div>
																		</div>
																	</div>
																</Col>
															</Row>														
														</div>
														))
														}
                                                </Tab.Pane>
												<Tab.Pane eventKey="review" className="overview-tab">
                                                    <div className="course-desc">
                                                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Este projeto foi aprovado no Edital n° 003/2020 do PROGRAMA DE APOIO À INTERIORIZAÇÃO EM PESQUISA E INOVAÇÃO TECNOLÓGICA NO AMAZONAS – PAINTER/FAPEAM.</p>
                                                    </div>
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </Tab.Container>
                                    </div>
                                </div>
                            </Col>
                            <Col lg="3" md="4" sm="12">
								{/*<div className="single-details-sidbar">
                                    <Row>
                                        <Col md="12">
                                            <div className="course-details-feature">
                                                <h5 className="title">Detalhs do Projeto</h5>
                                                <ul className="list-unstyled feature-list">
                                                    <li><i className="las la-calendar"></i> Início Date: <span>????</span></li>
                                                    <li><i className="las la-clock"></i> Duração: <span>????</span></li>
                                                    <li><i className="las la-graduation-cap"></i> Estudo: <span>????</span></li>
                                                    <li><i className="las la-book"></i> Palestrantes: <span>???</span></li>
                                                    <li><i className="las la-bookmark"></i> Inscritos: <span>236</span></li>
                                                </ul>
                                                {/*<button type="button" className="enroll-btn">Enroll Course</button>*/}
													{/*</div>
                                        </Col>
                                    </Row>
                                </div>
								*/}

                            </Col>
                        </Row>
                    </Container>
                </section>
            </Styles>

            {/* Footer 2 */}
            <Footer />

        </div >
    )
}
}
export default CourseDetails