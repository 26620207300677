import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
//import BlogSidebar from './components/BlogSidebar';
//import CommentForm from './components/CommentForm';
import Footer from '../../components/Footer';
import { Styles } from './styles/blogDetails.js';

class BlogDetails extends Component {
	//Construtor para listar blog
	constructor(props) {
		super(props);
		this.state=({
			db: [],
		});
		this.detalhesBlog();
	}
	
	
	detalhesBlog(idBlog) {
			fetch("http://www.gpefed.com.br/src/api/?acao=detalhesBlog&idBlog=2")
			.then((response)=>response.json())
			.then((responseJson)=>
			{
				this.setState({
					db: responseJson
				});
				//console.log(this.state.db);
			})
	}
	
    render() {
        return (
            <Styles>
                {/* Main Wrapper */}
                <div className="main-wrapper blog-details-page">

                    {/* Header 2 */}
                    <HeaderTwo />

                    {/* Breadcroumb */}
                    <BreadcrumbBox title="Blog" />

                    {/* Blog Details */}
                    <section className="blog-details-area">
                        <Container>
                            <Row>
							{
								this.state.db.map((data, i) => (
                                <Col lg="9" md="8" sm="7">
                                    <div className="blog-details-box">
                                        <div className="blog-details-banner">
                                            <img src={process.env.PUBLIC_URL + `/assets/images/${data.foto}`} alt="" className="img-fluid" />
                                        </div>
                                        <div className="heading">
                                            <h4>{data.titulo}</h4>
                                        </div>
                                        <div className="blog-auth_date d-flex">
                                            <div className="author-img d-flex">
                                                <Link to={process.env.PUBLIC_URL + "/"}><img src={process.env.PUBLIC_URL + `/assets/images/${data.fotoAutor}`} alt="" /></Link>
                                                <p><Link to={process.env.PUBLIC_URL + "/"}>{data.autor} da Silva&#185;</Link></p>
                                            </div>
                                            <div className="post-date">
												<p><i className="las la-calendar"></i>{data.data}</p>
                                            </div>
                                            <div className="post-category">
												{/*<p><Link to={process.env.PUBLIC_URL + "/"}><i className="las la-bookmark"></i> Web Design</Link></p>*/}
                                            </div>
                                            <div className="post-comment">
												{/*<p><Link to={process.env.PUBLIC_URL + "/"}><i className="las la-comment"></i> (23)</Link></p>*/}
                                            </div>
                                        </div>
                                        <div className="blog-details-desc">
                                            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{data.resumo} Em setembro de 2007, eu tive a oportunidade de vir do município de Uberlândia-MG para trabalhar como professor no Centro de Estudos Superiores de Tefé (CEST) da Universidade do Estado do Amazonas (UEA) em Tefé, região do Médio Solimões. Imerso nesta nova realidade e junto com outros colegas dos cursos de Licenciatura em História e Pedagogia tivemos a oportunidade de encontrar, de conhecer e organizar um rico acervo documental sobre a história e memória do Movimento de Educação de Base (MEB) de Tefé e região, só essa experiência já daria um outro relato, porém, gostaria de chamar atenção ao fato de que foi nesse acervo que organizamos, onde tive o primeiro contato com o material de alfabetização de adultos O Ribeirinho.</p>
											<p><br /></p>
											<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Antes de apresentar a leitora e ao leitor um pouco da história e princípios pedagógicos norteadores desse rico material de alfabetização de adultos preciso dizer que, em agosto de 2009, quando me mudei de Tefé com destino a Itacoatiara, região do Médio Amazonas, para assumir o cargo de professor no Instituto de Ciências Exatas e Tecnologia (ICET) da Universidade Federal do Amazonas (UFAM) também me deparei com as cartilhas O Ribeirinho neste município. Pois, ao realizar entrevistas com ex-coordenadoras(res) pedagógicos do MEB em Itacoatiara-AM eles me relataram o uso da cartilha já nos anos 1990, período de encerramento do MEB Amazonas no município.</p>
											<p><br /></p>
											<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A cartilha de alfabetização de adultos não ofereceu oportunidades somente para seus educandos e educandas, ofertou para mim também, pois o Ribeirinho junto com o material Poronga compuseram minha amostra de análise de conteúdo qualitativa de livros didáticos para a alfabetização emancipadora na tese de doutorado desenvolvida e defendida na Université de Montréal, Canadá em 2019. Dessa maneira, O primeiro projeto Seringueiro produziu as cartilhas Poronga, cartilhas totalmente desenvolvidas para o mundo dos seringueiros, muito explorados pelos donos dos seringais da região de Xapuri-Acre. O segundo projeto foi fruto da relação do MEB com o Centro Ecumênico de Documentação e Informação (CEDI) e da experiência do projeto Seringueiro que deu origem ao material didático O Ribeirinho, pensado justamente para a realidade da vida social, econômica e cultural dos ribeirinhos e ribeirinhas da região amazônica do Médio Solimões. Nesse sentido, cabe destacar que os projetos Poronga e O Ribeirinho foram experiências educacionais distintas da escola tradicional, pois buscavam uma educação de adultos que desenvolvesse uma alfabetização capaz de transformar a vida do educando para que ele se tornasse socialmente e economicamente emancipado por meio da educação, alfabetização e capacitação política. </p>
											<p><br /></p>
											<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;É importante destacar também que, epistemologicamente, os alicerces desse letramento emancipatório foram forjados a partir do princípio de que os saberes são diferentes e não superiores entre si – como Paulo Freire sempre destacou – e que a experiência de vida do educando pode ser valorizada juntamente com o conhecimento científico e não ser rejeitado ou considerado como algo inferior ou inválido (Freire, 1969, 1970). </p>
											<p><br /></p>
											<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Esse projeto durou três décadas e teve como foco a alfabetização de adultos de uma perspectiva libertadora. Devido ao sucesso da experiência em Xapuri, o sistema de alfabetização adotado foi assumido pelo MEB na região do Juruá, ainda na década de 1980. No início, com a mesma orientação do CEDI, foi produzido um livro didático de português (edição Juruá). Numa segunda etapa, foi produzido o material didático O Ribeirinho/Livro Didático de Alfabetização, com cartazes de motivação; o livro didático de Matemática (primeiras contas), também para educandos e monitores do MEB Amazonas (Tefé), especificamente adotado a partir de 1983/84, no ambiente da região do Solimões. Espero que tenham gostado deste relato de caminhada, ou melhor de remos na canoa da pesquisa em educação no interior do Amazonas.</p>
											<p><br /></p>
											<p><hr noshade /></p>
											<p>&#185;Prof. Adjunto III do Instituto de Ciências Exatas e Tecnologia (ICET) de Itacoatiara da Universidade Federal do Amazonas (UFAM). Vice-líder do Grupo de Pesquisa em Educação, Formação e Ensino para a Diversidade.</p>
											<p><br /></p>
											<p>Imagem de Destaque: Ribeirinho (1984). </p>
											<p>Fonte: <a href="http://pensaraeducacao.com.br/pensaraeducacaoempauta/a-origem-da-cartilha-de-alfabetizacao-de-adultos-o-ribeirinho/">http://pensaraeducacao.com.br</a></p>
											<p><br /></p>
                                            {/*<ul className="list-unstyled">
                                                <li><i className="fa fa-check"></i> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere Quae impedit eligendi perspiciatis animi maxime ab minus corporis omnis similique excepturi.</li>
                                                <li><i className="fa fa-check"></i> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere Quae impedit eligendi perspiciatis animi maxime ab minus corporis omnis similique excepturi.</li>
                                                <li><i className="fa fa-check"></i> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere Quae impedit eligendi perspiciatis animi maxime ab minus corporis omnis similique excepturi.</li>
                                            </ul>*/}
                                        </div>
                                        {/*<div className="blog-tag_share d-flex justify-content-between">
                                            <div className="blog-tag">
                                                <ul className="tags list-unstyled list-inline">
                                                    <li className="list-inline-item">Tags:</li>
                                                    <li className="list-inline-item"><Link to={process.env.PUBLIC_URL + "/"}>Html</Link>,</li>
                                                    <li className="list-inline-item"><Link to={process.env.PUBLIC_URL + "/"}>Design</Link>,</li>
                                                    <li className="list-inline-item"><Link to={process.env.PUBLIC_URL + "/"}>Develop</Link>,</li>
                                                    <li className="list-inline-item"><Link to={process.env.PUBLIC_URL + "/"}>React</Link></li>
                                                </ul>
                                            </div>
                                            <div className="blog-share">
                                                <ul className="social list-unstyled list-inline">
                                                    <li className="list-inline-item">Share:</li>
                                                    <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-facebook-f"></i></a></li>
                                                    <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-twitter"></i></a></li>
                                                    <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-linkedin-in"></i></a></li>
                                                    <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-youtube"></i></a></li>
                                                    <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-dribbble"></i></a></li>
                                                </ul>
                                            </div>
                                        </div>
										*/}
										{/*
                                        <div className="blog-comments">
                                            <h5>Comments (03)</h5>
                                            <div className="comment-box d-flex">
                                                <div className="comment-image">
                                                    <img src={process.env.PUBLIC_URL + `/assets/images/testimonial-2.jpg`} alt="" />
                                                </div>
                                                <div className="comment-content">
                                                    <div className="content-title d-flex justify-content-between">
                                                        <div className="comment-writer">
                                                            <h6>Mark Shadow</h6>
                                                            <p>Mar 26, 2020 | 06:30pm</p>
                                                        </div>
                                                        <div className="reply-btn">
                                                            <button type="button"><i className="las la-reply-all"></i> Reply</button>
                                                        </div>
                                                    </div>
                                                    <div className="comment-desc">
                                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto laborum quas placeat perspiciatis est, nisi expedita consectetur sit minus illum laudantium nostrum dolore odit asperiores quisquam ad enim iusto laborum quas placeat perspiciatis saepe.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="comment-box d-flex">
                                                <div className="comment-image">
                                                    <img src={process.env.PUBLIC_URL + `/assets/images/testimonial-1.jpg`} alt="" />
                                                </div>
                                                <div className="comment-content">
                                                    <div className="content-title d-flex justify-content-between">
                                                        <div className="comment-writer">
                                                            <h6>Katrin Kay</h6>
                                                            <p>Mar 26, 2020 | 06:30pm</p>
                                                        </div>
                                                        <div className="reply-btn">
                                                            <button type="button"><i className="las la-reply-all"></i> Reply</button>
                                                        </div>
                                                    </div>
                                                    <div className="comment-desc">
                                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto laborum quas placeat perspiciatis est, nisi expedita consectetur sit minus illum laudantium nostrum dolore odit asperiores quisquam ad enim iusto laborum quas placeat perspiciatis saepe.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="comment-box d-flex">
                                                <div className="comment-image">
                                                    <img src={process.env.PUBLIC_URL + `/assets/images/testimonial-2.jpg`} alt="" />
                                                </div>
                                                <div className="comment-content">
                                                    <div className="content-title d-flex justify-content-between">
                                                        <div className="comment-writer">
                                                            <h6>David Show</h6>
                                                            <p>Mar 26, 2020 | 06:30pm</p>
                                                        </div>
                                                        <div className="reply-btn">
                                                            <button type="button"><i className="las la-reply-all"></i> Reply</button>
                                                        </div>
                                                    </div>
                                                    <div className="comment-desc">
                                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto laborum quas placeat perspiciatis est, nisi expedita consectetur sit minus illum laudantium nostrum dolore odit asperiores quisquam ad enim iusto laborum quas placeat perspiciatis saepe.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <CommentForm />
										*/}
                                    </div>
                                </Col>
								))
							}
                                <Col lg="3" md="4" sm="5">
									{/*<BlogSidebar />*/}
                                </Col>
                            </Row>
                        </Container>
                    </section>

                    {/* Footer */}
                    <Footer />

                </div>
            </Styles>
        )
    }
}

export default BlogDetails