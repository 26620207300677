import React, { Component } from 'react';
//import Datas from '../../data/instructor/details.json';
//import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
//import Swiper from 'react-id-swiper';
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import Footer from '../../components/Footer';
import { Styles } from './styles/instructor.js';

class InstructorDetails extends Component {

	//Construtor para buscar dados do pesquisador
	constructor(props) {
		super(props);
		this.state=({
			dbPesquisadorDetalhe: [], 
			dbPesquisadorQualificacao: []
		});
		
		this.detalhesPesquisador(this.props.match.params.idPesquisador);
		this.qualificacoesPesquisador(this.props.match.params.idPesquisador);
	}
	
	detalhesPesquisador(idPesquisador) {
		fetch("http://www.gpefed.com.br/src/api/?acao=detalhesPesquisador&idPesquisador="+idPesquisador)
		.then((response)=>response.json())
		.then((responseJson)=>
		{
			
			this.setState({
				dbPesquisadorDetalhe: responseJson
			});
		})
		
	}
	
	qualificacoesPesquisador(idPesquisador) {
		fetch("http://www.gpefed.com.br/src/api/?acao=qualificacaoPesquisador&idPesquisador="+idPesquisador)
		.then((response)=>response.json())
		.then((responseJson)=>
		{
			
			this.setState({
				dbPesquisadorQualificacao: responseJson
			});
		})
		
	}
	
	render() {
        return (
            <Styles>
                {/* Main Wrapper */}
                <div className="main-wrapper instructor-details-page">

                    {/* Header 2 */}
                    <HeaderTwo />

                    {/* Breadcroumb */}
                    <BreadcrumbBox title={this.props.match.params.page} />
					
					{/* Instructor Details Area */}
					<section className="instructor-details-area">
						<Container>
							{
							this.state.dbPesquisadorDetalhe.map((pesquisador) => (
							<Row>
								<Col md="4">
									<div className="instructor-img">
										<img src={process.env.PUBLIC_URL + `/assets/images/${pesquisador.foto}`} alt="" className="img-fluid" />
										<ul className="list-unstyled getintouch">
											{/*<li><i className="las la-phone"></i> +1 (396) 486 4709</li>*/}
											<li><i className="lar la-envelope"></i>{pesquisador.email}</li>
											{/*<li><i className="lab la-skype"></i> contact.me</li>*/}
										</ul>
										<ul className="list-unstyled list-inline">
											<li className="list-inline-item">
												<div className="img">
													<a href={pesquisador.orcid} target="_blank" rel="noopener noreferrer">
														<img src={process.env.PUBLIC_URL + `/assets/images/iconID_48_48.jpg`} alt="Orcid" />													
													</a>
												</div>
											</li>
											<li className="list-inline-item">
												<div className="img">
													<a href={pesquisador.lattes} target="_blank" rel="noopener noreferrer">
														<img src={process.env.PUBLIC_URL + `/assets/images/iconLATTES_48_48.jpg`} alt="Lattes" />
													</a>
												</div>
											</li>
											{/*<li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-facebook-f"></i></a></li>*/}
											{/*<li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-twitter"></i></a></li>*/}
											{/*<li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-linkedin-in"></i></a></li>*/}
											{/*<li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-youtube"></i></a></li>*/}
											{/*<li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-dribbble"></i></a></li>*/}
										</ul>
									</div>
								</Col>
								<Col md="8" key="dadosBasicos">
									<div className="instructor-content">
										<h4>{pesquisador.nomeCompleto}</h4>
										<span>{pesquisador.qualificacao}</span>
										<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{pesquisador.descricao}</p>
									</div>
									<div className="qual-expe d-flex">
										<div className="qualification">
											<h5>Qualificações</h5>
											{
											this.state.dbPesquisadorQualificacao.map((qualificacao) => (
											<div className="qual-expe-box">
												<h6>{qualificacao.local}</h6>
												<p>{qualificacao.qualificacao}</p>
											</div>
											))
											}
										</div>
										{/*
										<div className="experiance">
											<h5>Experiance</h5>
											<div className="qual-expe-box">
												<h6>SnazzyTheme.com</h6>
												<p>2016 - 2019</p>
											</div>
											<div className="qual-expe-box">
												<h6>Envato Market</h6>
												<p>2019 - Present</p>
											</div>
										</div>
										*/}
									</div>
								</Col>
								{/*
								<Col md="12">
									<div className="instructor-course-title">
										<h5>Courses by Kamal Sulaiman</h5>
									</div>
									<div className="instructor-course-slider">
										<Swiper {...settings}>
											{ 
												Datas.map((data, i) => (
													<div className="course-item" key={i}>
														<Link to={process.env.PUBLIC_URL + data.courseLink}>
															<div className="course-image" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/${data.imgUrl})` }}>
																<div className="author-img d-flex">
																	<div className="img">
																		<img src={process.env.PUBLIC_URL + `/assets/images/${data.authorImg}`} alt="" />
																	</div>
																	<div className="title">
																		<p>{data.authorName}</p>
																		<span>{data.authorCourses}</span>
																	</div>
																</div>
																<div className="course-price">
																	<p>{data.price}</p>
																</div>
															</div>
														</Link>
														<div className="course-content">
															<h6 className="heading"><Link to={process.env.PUBLIC_URL + data.courseLink}>{data.courseTitle}</Link></h6>
															<p className="desc">{data.courseDesc}</p>
															<div className="course-face d-flex justify-content-between">
																<div className="duration">
																	<p><i className="las la-clock"></i>120</p>
																</div>
																<div className="rating">
																	<ul className="list-unstyled list-inline">
																		<li className="list-inline-item"><i className="las la-star"></i></li>
																		<li className="list-inline-item"><i className="las la-star"></i></li>
																		<li className="list-inline-item"><i className="las la-star"></i></li>
																		<li className="list-inline-item"><i className="las la-star"></i></li>
																		<li className="list-inline-item"><i className="las la-star-half-alt"></i>
																		</li>
																		<li className="list-inline-item">(4.5)</li>
																	</ul>
																</div>
																<div className="student">
																	<p><i className="las la-chair"></i>60</p>
																</div>
															</div>
														</div>
													</div>
												))
											}
										</Swiper>
									</div>
								</Col>
								*/}
							</Row>
							))
							}
						</Container>
					</section>
                    {/* Footer 1 */}
                    <Footer />
                </div>
            </Styles>
        )
    }
}

export default InstructorDetails