import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ModalImage from "react-modal-image";
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import { StylesPaginacao } from "./styles/pagination.js";
import Footer from '../../components/Footer';
import { Styles } from './styles/gallery.js';

class Gallery extends Component {
	//Construtor para listar projetos
	constructor(props) {
		super(props);
		this.pgAnt = null;
		this.pgNov = null;
		this.state=({
			dbGaleria: [], 
			dbPaginacao: []
		});
		this.paginacaoGaleria();
		//this.listarGaleria();
	}
	
	listarGaleria(pagina) {
			fetch("http://www.gpefed.com.br/src/api/?acao=listarGaleria&pagina="+pagina)
			.then((response)=>response.json())
			.then((responseJson)=>
			{
				this.setState({
					dbGaleria: responseJson
				});
				//console.log(this.state.db);
			})
	}
	
	paginacaoGaleria() {
			fetch("http://www.gpefed.com.br/src/api/?acao=paginacaoGaleria")
			.then((response)=>response.json())
			.then((responseJsonPaginacao)=>
			{
				this.setState({
					dbPaginacao: responseJsonPaginacao
				});
				//console.log(this.state.dbPaginacao);
			})
	}
    render() {
		this.pgNov = this.props.match.params.pagina;
		if( this.pgAnt !== this.pgNov ) {
			this.listarGaleria(this.props.match.params.pagina);
			this.pgAnt = this.props.match.params.pagina;
		}
		
        return (
            <Styles>
                {/* Main Wrapper */}
                <div className="main-wrapper gallery-page">
 
                    {/* Header 2 */}
                    <HeaderTwo />

                    {/* Breadcroumb */}
                    <BreadcrumbBox title="Galeria de Imagens" />

                    {/* Gallery Area */}
                    <section className="gallery-page-area">
                        <Container>
                            <Row>
                                {
                                    this.state.dbGaleria.map((data, i) => (
                                        <Col lg="4" sm="6" key={i}>
                                            <div className="gallery-box">
                                                <ModalImage small={process.env.PUBLIC_URL + `/assets/images/${data.caminho}`} hideDownload="1" hideZoom="1"  large={process.env.PUBLIC_URL + `/assets/images/${data.caminho}`} alt="" />{data.legenda}
                                            </div>
                                        </Col>
                                    ))
                                }
                                <Col md="12" className="text-center">
									<StylesPaginacao>
										{/* Pagination */}
										<ul className="pagination-box list-unstyled list-inline">
										{/*<li className="list-inline-item"><Link to={process.env.PUBLIC_URL + "/"}><i className="las la-angle-double-left"></i></Link></li>*/}
												{
													this.state.dbPaginacao.map((dataPaginacao, i) => {
														if ( String(dataPaginacao.pagina) === String(this.props.match.params.pagina) ) { 
															return <li className="active list-inline-item"><Link to={process.env.PUBLIC_URL + `/gallery/${dataPaginacao.pagina}`}>{dataPaginacao.pagina}</Link></li>
														} else {
															return <li className="list-inline-item"><Link to={process.env.PUBLIC_URL + `/gallery/${dataPaginacao.pagina}`}>{dataPaginacao.pagina}</Link></li>
														}
													})
												}
											{/*<li className="list-inline-item"><Link to={process.env.PUBLIC_URL + "/"}>2</Link></li>
											<li className="active list-inline-item"><Link to={process.env.PUBLIC_URL + "/"}>3</Link></li>
											<li className="list-inline-item"><Link to={process.env.PUBLIC_URL + "/"}>...</Link></li>
											<li className="list-inline-item"><Link to={process.env.PUBLIC_URL + "/"}>13</Link></li>*/}
											{/*<li className="list-inline-item"><Link to={process.env.PUBLIC_URL + "/"}><i className="las la-angle-double-right"></i></Link></li>*/}
										</ul>
									</StylesPaginacao>
                                </Col>
                            </Row>
                        </Container>
                    </section>

                    {/* Footer */}
                    <Footer />

                </div>
            </Styles>
        )
    }
}

export default Gallery