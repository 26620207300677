import React, { Component } from 'react';
import { Container, Row, Col, Tab, Nav } from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
//import ReviewForm from './components/ReviewForm';
//import PopularCourse from './components/PopularCourse';
//import CourseTag from './components/CourseTag';
import Footer from '../../components/Footer';
import { Styles } from './styles/course.js';

class CourseDetails extends Component {
	//Construtor para buscar dados do pesquisador
	constructor(props) {
		super(props);
		this.state=({
			dbProjetoPesquisadores: [], 
			dbPesquisadorQualificacao: []
		});
		
		this.detalhesPesquisador();
	}
	
	detalhesPesquisador() {
		fetch("http://www.gpefed.com.br/src/api/?acao=listarProjetosPesquisadores&idProjeto=3")
		.then((response)=>response.json())
		.then((responseJson)=>
		{
			
			this.setState({
				dbProjetoPesquisadores: responseJson
			});
		})
		
	}

	render() {
    return (
        <div className="main-wrapper course-details-page" >

            {/* Header 2 */}
            < HeaderTwo />

            {/* Breadcroumb */}
            < BreadcrumbBox title="Projetos" />

            <Styles>
                {/* Course Details */}
                <section className="course-details-area">
                    <Container>
                        <Row>
                            <Col lg="9" md="8" sm="12">
                                <div className="course-details-top">
                                    <div className="heading">
                                        <h4>PIBIC: Professores de Física e Matemática: Análise da Formação, Metodologias e Estratégias dos professores que lecionam Física e Matemática para alunos do Ensino Médio nas escolas do Município de Itacoatiara.</h4>
                                    </div>
                                    <div className="course-top-overview">
                                        <div className="d-flex overviews">
                                            <div className="author">
                                                <img src={process.env.PUBLIC_URL + `/assets/images/nayana_teles_280_280.jpg`} alt="" />
                                                <div className="author-name">
                                                    <h6>Autor(a)</h6>
                                                    <p>Nayana Teles</p>
                                                </div>
                                            </div>
                                            {/*<div className="category">
                                                <h6>Categoria</h6>
                                                <p>????</p>
                                            </div>*/}
                                        </div>
                                    </div>
                                    <div className="course-details-banner">
                                        <img src={process.env.PUBLIC_URL + `/assets/images/projeto_03_1920_1161.jpg`} alt="" className="img-fluid" />
                                    </div>
                                    <div className="course-tab-list">
                                        <Tab.Container defaultActiveKey="overview">
                                            <Nav className="flex-column">
                                                <Nav.Item>
                                                    <Nav.Link eventKey="overview">Resumo</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="curriculum">Objetivos</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="instructor">Equipe</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="review">Fomento</Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                            <Tab.Content>
                                                <Tab.Pane eventKey="overview" className="overview-tab">
                                                    <div className="course-desc">
                                                        <h5>Resumo</h5>
                                                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;O projeto de pesquisa aqui proposto tem por objetivo geral analisar como vem se desenvolvendo o ensino de Física e Matemática nas escolas de ensino médio do município de Itacoatiara, formulando um diagnóstico e uma proposta de intervenção para a realidade investigada. </p>
														<br />
														<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Trata-se de uma pesquisa qualitativa que reúne aspectos de pesquisas exploratórias, descritivas e analíticas. Como procedimentos para a coleta de dados serão utilizados a aplicação de questionários, a entrevista semiestruturada, a observação e os grupos de discussão.</p>
														<br />
														<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A justificativa para a realização desse estudo está no desafio que o ensino de ciências encontra no Brasil, embora seja consenso na literatura do campo da formação docente que são necessários diferentes saberes na atuação do professor na sala de aula, os cursos de formação inicial seguem dedicando a maior parte da carga horária aos saberes do conteúdo deslocados dos saberes pedagógicos e metodológicos que os viabilizam, em igual medida, ainda prevalece nesses cursos a frágil articulação entre teoria e prática, e entre universidades e escolas. Soma-se a essa realidade a prática, ainda muito presente em diversas secretarias de ensino, de oferecer cursos de formação continuada elaborada por especialistas sem uma ligação concreta com os desafios cotidianos das salas de aula.</p>
														<br />
														<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Assim, ancorada nas ideias de autores como Day (2001), Garcia (1999) e Imbernón (2001, 2006), objetiva-se como resultados desse projeto, a elaboração de projetos de formação continuada de professores coerentes com as necessidades formativas dos docentes da rede pública de educação, centradas no seu desenvolvimento profissional, além de retroalimentar a formação inicial nas licenciaturas do ICET a partir da compreensão dos problemas concretos vivenciados nas escolas, colaborando para a construção de projetos pedagógicos curriculares que melhor qualifiquem os professores para o exercício da docência.</p>
                                                    </div>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="curriculum" className="overview-tab">
                                                    <div className="course-desc">
                                                        <h5>Objetivo Geral</h5>
                                                    </div>
                                                    <div className="course-feature">
                                                        <ul className="list-unstyled">
                                                            <li><i className="las la-arrow-right"></i>Analisar como vem se desenvolvendo o ensino de Física e Matemática nas escolas de ensino médio do município de Itacoatiara, formulando um diagnóstico e uma proposta de intervenção para a realidade investigada.</li>
                                                        </ul>
                                                    </div>
                                                    <div className="course-learn">
                                                        <h5>Objetivos Específicos</h5>
                                                        <ul className="list-unstyled">
                                                            <li><i className="fa fa-check"></i>Identificar quem são e qual a formação dos professores que lecionam as disciplinas de Física e Matemática nas escolas de ensino médio do município de Itacoatiara;</li>
                                                            <li><i className="fa fa-check"></i>Descrever as principais metodologias e estratégias de ensino-aprendizagem utilizadas pelos professores que lecionam essas - Discutir os principais desafios que os professores enfrentam no ensino de física e matemática e as principais estratégias utilizadas pelos docentes para o enfrentamento das dificuldades;</li>
                                                            <li><i className="fa fa-check"></i>Construir um ementário de formação que possa nortear a elaboração e desenvolvimento das atividades de formação continuada da Coordenadoria Regional de Ensino (SEDUC/AM) em parceria com o Instituto de Ciências Exatas e Tecnologias da Universidade Federal do Amazonas.</li>
                                                        </ul>
                                                    </div>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="instructor" className="instructor-tab">
														{
														this.state.dbProjetoPesquisadores.map((pesquisador) => (
														<div className="instructor-item">
															<Row>
																<Col md="4">
																	<div className="instructor-img">
																		<img src={process.env.PUBLIC_URL + `/assets/images/${pesquisador.fotoPequena}`} alt="" className="img-fluid" />
																	</div>
																</Col>
																<Col md="8">
																	<div className="instructor-content">
																		<div className="instructor-box">
																			<div className="top-content d-flex justify-content-between">
																				<div className="instructor-name">
																					<h6>{pesquisador.nomeCompleto}</h6>
																					<p>{pesquisador.legenda}</p>
																				</div>
																				<div className="instructor-social">
																					<ul className="social list-unstyled list-inline">
																						<li className="list-inline-item">
																							<a href={pesquisador.orcid} target="_blank" rel="noopener noreferrer">
																								<img src={process.env.PUBLIC_URL + `/assets/images/iconID_48_48.jpg`} alt="Lattes" />
																							</a>
																						</li>
																						<li className="list-inline-item">
																							<a href={pesquisador.lattes} target="_blank" rel="noopener noreferrer">
																								<img src={process.env.PUBLIC_URL + `/assets/images/iconLATTES_48_48.jpg`} alt="Lattes" />
																							</a>
																						</li>
																					</ul>
																				</div>
																			</div>
																			<div className="instructor-desk">
																				<p>{pesquisador.qualificacao}</p>
																			</div>
																		</div>
																	</div>
																</Col>
															</Row>														
														</div>
														))
														}
                                                </Tab.Pane>
												<Tab.Pane eventKey="review" className="overview-tab">
                                                    <div className="course-desc">
                                                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Este projeto foi aprovado no Edital n° 081/2019 – PROPESP/UFAM e conta com financiamento da Fundação de Amparo à Pesquisa do Estado do Amazonas/FAPEAM.</p>
                                                    </div>
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </Tab.Container>
                                    </div>
                                </div>
                            </Col>
                            <Col lg="3" md="4" sm="12">
								{/*<div className="single-details-sidbar">
                                    <Row>
                                        <Col md="12">
                                            <div className="course-details-feature">
                                                <h5 className="title">Detalhs do Projeto</h5>
                                                <ul className="list-unstyled feature-list">
                                                    <li><i className="las la-calendar"></i> Início Date: <span>????</span></li>
                                                    <li><i className="las la-clock"></i> Duração: <span>????</span></li>
                                                    <li><i className="las la-graduation-cap"></i> Estudo: <span>????</span></li>
                                                    <li><i className="las la-book"></i> Palestrantes: <span>???</span></li>
                                                    <li><i className="las la-bookmark"></i> Inscritos: <span>236</span></li>
                                                </ul>
                                                {/*<button type="button" className="enroll-btn">Enroll Course</button>*/}
													{/*</div>
                                        </Col>
                                    </Row>
                                </div>
								*/}

                            </Col>
                        </Row>
                    </Container>
                </section>
            </Styles>

            {/* Footer 2 */}
            <Footer />

        </div >
    )
}
}
export default CourseDetails