import React, { Component } from 'react';
import { Container, Row, Col, Tab, Nav } from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
//import ReviewForm from './components/ReviewForm';
//import PopularCourse from './components/PopularCourse';
//import CourseTag from './components/CourseTag';
import Footer from '../../components/Footer';
import { Styles } from './styles/course.js';

class CourseDetails extends Component {
	//Construtor para buscar dados do pesquisador
	constructor(props) {
		super(props);
		this.state=({
			dbProjetoPesquisadores: [], 
			dbPesquisadorQualificacao: []
		});
		
		this.detalhesPesquisador();
	}
	
	detalhesPesquisador() {
		fetch("http://www.gpefed.com.br/src/api/?acao=listarProjetosPesquisadores&idProjeto=7")
		.then((response)=>response.json())
		.then((responseJson)=>
		{
			
			this.setState({
				dbProjetoPesquisadores: responseJson
			});
		})
		
	}

	render() {
    return (
        <div className="main-wrapper course-details-page" >

            {/* Header 2 */}
            < HeaderTwo />

            {/* Breadcroumb */}
            < BreadcrumbBox title="Projetos" />

            <Styles>
                {/* Course Details */}
                <section className="course-details-area">
                    <Container>
                        <Row>
                            <Col lg="9" md="8" sm="12">
                                <div className="course-details-top">
                                    <div className="heading">
                                        <h4>A MEMÓRIA BIOCULTURAL E OS SABERES TRADICIONAIS NO ENFRENTAMENTO DA COVI 19 NA ÁREA INDÍGENA DO RIO URUBU MUNICÍPIO DE ITACOATIARA-AM</h4>
                                    </div>
                                    <div className="course-top-overview">
                                        <div className="d-flex overviews">
                                            <div className="author">
                                                <img src={process.env.PUBLIC_URL + `/assets/images/ignes_tereza_280_280.jpg`} alt="" />
                                                <div className="author-name">
                                                    <h6>Autor(a)</h6>
                                                    <p>Ignês Tereza</p>
                                                </div>
                                            </div>
                                            {/*<div className="category">
                                                <h6>Categoria</h6>
                                                <p>????</p>
                                            </div>*/}
                                        </div>
                                    </div>
                                    <div className="course-details-banner">
                                        <img src={process.env.PUBLIC_URL + `/assets/images/projeto_07_1920_1161.jpg`} alt="" className="img-fluid" />
                                    </div>
                                    <div className="course-tab-list">
                                        <Tab.Container defaultActiveKey="overview">
                                            <Nav className="flex-column">
                                                <Nav.Item>
                                                    <Nav.Link eventKey="overview">Resumo</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="curriculum">Objetivos</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="instructor">Equipe</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="review">Fomento</Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                            <Tab.Content>
                                                <Tab.Pane eventKey="overview" className="overview-tab">
                                                    <div className="course-desc">
                                                        <h5>Resumo</h5>
                                                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;O projeto de pesquisa em pauta que tem por título A MEMÓRIA BIOCULTURAL E OS SABERES TRADICIONAIS NO ENFRENTAMENTO DA COVI 19 NA ÁREA INDÍGENA DO RIO URUBU MUNICÍPIO DE ITACOATIARA-AM.  como objeto de estudo investigar a memória biocultural das plantas medicinais utilizadas no processo de cura e como esses saberes tradicionais se articulam com as relações sociais e ambientais. Será desenvolvida nas aldeias da área indígena do Rio Urubu, na Aldeia Maquira. A área indígena é oficialmente reconhecida em nome da etnia Mura. O emprego de plantas medicinais para a manutenção e a recuperação da saúde tem ocorrido ao longo dos tempos desde as formas mais simples de tratamento local até as formas mais sofisticadas de fabricação industrial de medicamentos. Portanto o fator que motiva a realização desta pesquisa é o seu caráter interdisciplinar, que articula os conhecimentos da áreas da Antropologia, Sociologia e Biologia que irão compor uma investigação compartilhada entre os saberes tradicionais e o saber científico sobre as plantas medicinais utilizadas pelos indígenas no enfrentamento da COVID-19 na área indígena do Rio Urubu. Para esta pesquisa far-se-á pesquisa qualitativa, na tentativa de atingir os objetivos por elas explicitados. A escolha por este tipo de pesquisa se dá pelo fato do pesquisador ser meio de obtenção da coleta de dados, mediante a observação e interação com o ambiente de estudo. a pesquisa irá investigar a utilização das plantas medicinais que serão catalogadas e classificadas de acordo com as espécimes das famílias diferentes da Botânica.</p>
														<br />
														<p>Vigência: 15/06/2020 a 30/08/2021.</p>
                                                    </div>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="curriculum" className="overview-tab">
                                                    <div className="course-desc">
                                                        <h5>Objetivo Geral</h5>
                                                    </div>
                                                    <div className="course-feature">
                                                        <ul className="list-unstyled">
                                                            <li><i className="las la-arrow-right"></i>Investigar a memória biocultural e os saberes tradicionais das plantas medicinais curativas no enfrentamento da COVID 19 na Aldeia Maquira na área indígena do Rio Urubu</li>
                                                        </ul>
                                                    </div>
                                                    <div className="course-learn">
                                                        <h5>Objetivos Específicos</h5>
                                                        <ul className="list-unstyled">
                                                            <li><i className="fa fa-check"></i>Identificar a memória biocultural das plantas medicinais e sua relação com os saberes tradicionais e a identidade dos povos indígenas residentes na área Indígena do Rio Urubu;</li>
                                                            <li><i className="fa fa-check"></i>Catalogar a memória biocultural das plantas medicinais na utilização no processo de cura de doenças pelos indígenas, e como os saberes tradicionais se articulam com às relações sociais e ambientais.</li>
                                                        </ul>
                                                    </div>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="instructor" className="instructor-tab">
														{
														this.state.dbProjetoPesquisadores.map((pesquisador) => (
														<div className="instructor-item">
															<Row>
																<Col md="4">
																	<div className="instructor-img">
																		<img src={process.env.PUBLIC_URL + `/assets/images/${pesquisador.fotoPequena}`} alt="" className="img-fluid" />
																	</div>
																</Col>
																<Col md="8">
																	<div className="instructor-content">
																		<div className="instructor-box">
																			<div className="top-content d-flex justify-content-between">
																				<div className="instructor-name">
																					<h6>{pesquisador.nomeCompleto}</h6>
																					<p>{pesquisador.legenda}</p>
																				</div>
																				<div className="instructor-social">
																					<ul className="social list-unstyled list-inline">
																						<li className="list-inline-item">
																							<a href={pesquisador.orcid} target="_blank" rel="noopener noreferrer">
																								<img src={process.env.PUBLIC_URL + `/assets/images/iconID_48_48.jpg`} alt="Lattes" />
																							</a>
																						</li>
																						<li className="list-inline-item">
																							<a href={pesquisador.lattes} target="_blank" rel="noopener noreferrer">
																								<img src={process.env.PUBLIC_URL + `/assets/images/iconLATTES_48_48.jpg`} alt="Lattes" />
																							</a>
																						</li>
																					</ul>
																				</div>
																			</div>
																			<div className="instructor-desk">
																				<p>{pesquisador.qualificacao}</p>
																			</div>
																		</div>
																	</div>
																</Col>
															</Row>														
														</div>
														))
														}
                                                </Tab.Pane>
												<Tab.Pane eventKey="review" className="overview-tab">
                                                    <div className="course-desc">
                                                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A presente pesquisa foi aprovada na Chamada/Edital 081/2019 – PROPESP/UFAM</p>
                                                    </div>
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </Tab.Container>
                                    </div>
                                </div>
                            </Col>
                            <Col lg="3" md="4" sm="12">
								{/*<div className="single-details-sidbar">
                                    <Row>
                                        <Col md="12">
                                            <div className="course-details-feature">
                                                <h5 className="title">Detalhs do Projeto</h5>
                                                <ul className="list-unstyled feature-list">
                                                    <li><i className="las la-calendar"></i> Início Date: <span>????</span></li>
                                                    <li><i className="las la-clock"></i> Duração: <span>????</span></li>
                                                    <li><i className="las la-graduation-cap"></i> Estudo: <span>????</span></li>
                                                    <li><i className="las la-book"></i> Palestrantes: <span>???</span></li>
                                                    <li><i className="las la-bookmark"></i> Inscritos: <span>236</span></li>
                                                </ul>
                                                {/*<button type="button" className="enroll-btn">Enroll Course</button>*/}
													{/*</div>
                                        </Col>
                                    </Row>
                                </div>
								*/}

                            </Col>
                        </Row>
                    </Container>
                </section>
            </Styles>

            {/* Footer 2 */}
            <Footer />

        </div >
    )
}
}
export default CourseDetails