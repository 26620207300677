import React, { Component } from 'react';
import { Container, Row, Col, Tab, Nav } from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
//import ReviewForm from './components/ReviewForm';
//import PopularCourse from './components/PopularCourse';
//import CourseTag from './components/CourseTag';
import Footer from '../../components/Footer';
import { Styles } from './styles/course.js';

class CourseDetails extends Component {
	//Construtor para buscar dados do pesquisador
	constructor(props) {
		super(props);
		this.state=({
			dbProjetoPesquisadores: [], 
			dbPesquisadorQualificacao: []
		});
		
		this.detalhesPesquisador();
	}
	
	detalhesPesquisador() {
		fetch("http://www.gpefed.com.br/src/api/?acao=listarProjetosPesquisadores&idProjeto=1")
		.then((response)=>response.json())
		.then((responseJson)=>
		{
			
			this.setState({
				dbProjetoPesquisadores: responseJson
			});
		})
		
	}

	render() {
    return (
        <div className="main-wrapper course-details-page" >

            {/* Header 2 */}
            < HeaderTwo />

            {/* Breadcroumb */}
            < BreadcrumbBox title="Projetos" />

            <Styles>
                {/* Course Details */}
                <section className="course-details-area">
                    <Container>
                        <Row>
                            <Col lg="9" md="8" sm="12">
                                <div className="course-details-top">
                                    <div className="heading">
                                        <h4>Mentoria de Professores Iniciantes de Ciências</h4>
                                    </div>
                                    <div className="course-top-overview">
                                        <div className="d-flex overviews">
                                            <div className="author">
                                                <img src={process.env.PUBLIC_URL + `/assets/images/nayana_teles_280_280.jpg`} alt="" />
                                                <div className="author-name">
                                                    <h6>Autor(a)</h6>
                                                    <p>Nayana Teles</p>
                                                </div>
                                            </div>
                                            {/*<div className="category">
                                                <h6>Categoria</h6>
                                                <p>????</p>
                                            </div>*/}
                                        </div>
                                    </div>
                                    <div className="course-details-banner">
                                        <img src={process.env.PUBLIC_URL + `/assets/images/projeto_01_1920_1161.jpg`} alt="" className="img-fluid" />
                                    </div>
                                    <div className="course-tab-list">
                                        <Tab.Container defaultActiveKey="overview">
                                            <Nav className="flex-column">
                                                <Nav.Item>
                                                    <Nav.Link eventKey="overview">Resumo</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="curriculum">Objetivos</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="instructor">Equipe</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="review">Fomento</Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                            <Tab.Content>
                                                <Tab.Pane eventKey="overview" className="overview-tab">
                                                    <div className="course-desc">
                                                        <h5>Resumo</h5>
                                                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;O principal objetivo do projeto é auxiliar os professores iniciantes das áreas Matemática, Ciências, Biologia, Química e Física, a se inserirem na realidade escolar de uma forma mais adequada, estabelecendo estratégias que permitam minimizar o choque com a realidade, além de oferecer apoio didático, pessoal e técnico para o desenvolvimento de atividades e práticas docentes potencializadoras da aprendizagem dos estudantes. Para alcançar este objetivo, o projeto é desenvolvido em torno de 3 eixos de atuação, pesquisa, pois é preciso conhecer a realidade do ensino destas disciplinas no município para que se possa planejar e executar estratégias de intervenção; formação de assistentes de docência, com o intuito de auxiliar os professores  desde o planejamento e a confecção de material pedagógico até a realização das aulas, além de proporcionar aos futuros professores uma experiência de estágio significativa e estimulante a permanência na profissão; e mentoria.</p>
														<br />
														<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;O Projeto Mentoria de Professores Iniciantes de Ciências reúne ações de ensino, pesquisa e extensão, tornando-se, hoje, um projeto “guarda-chuva”, pois abriga outros projetos de iniciação científica, além de integrar programas como o Residência Pedagógica. </p>
														<br />
														<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Pretende-se, com este projeto promover o Desenvolvimento Profissional dos professores iniciantes, fortalecendo a parceria da Universidade com as Secretarias Municipal e Estadual de Educação e fomentando uma cultura colaborativa entre universidade e escolas. Também são metas deste projeto inserir os alunos, futuros professores, na rotina da escola, levando-os a com ela contribuir através de sua atuação profissional, integrando a aprendizagem e a formação dos futuros docentes ao espaço de aprendizagem concreto da escola, retroalimentando e modificando, sempre que necessário, a formação recebida na graduação.</p>
                                                    </div>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="curriculum" className="overview-tab">
                                                    <div className="course-desc">
                                                        <h5>Objetivos</h5>
                                                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;O Projeto está didaticamente dividido em 3 eixos de atuação:</p>
                                                    </div>
                                                    <div className="course-feature">
                                                        <ul className="list-unstyled">
                                                            <li><i className="las la-arrow-right"></i><strong>Mentoria de professores de ciências</strong> que atuam a menos de cinco anos nas escolas da rede pública de ensino, por professores do ICET da área de educação e das áreas específicas; </li>
                                                            <li><i className="las la-arrow-right"></i><strong>Assistência à Docência:</strong> Os alunos do ICET atuarão como monitores e acompanharão o planejamento e desenvolvimento das atividades dos professores tutorados, auxiliando-os desde o planejamento e a confecção de material pedagógico até a realização das aulas;</li>
                                                            <li><i className="las la-arrow-right"></i><strong>Pesquisa:</strong> Conhecer a realidade escolar e os desafios da atuação profissional dos docentes iniciantes de forma a orientar a elaboração de projetos de formação continuada de professores, em parceria com as secretarias municipal e estadual de educação, além de retroalimentar a formação inicial de professores nas licenciaturas do ICET a partir da compreensão dos problemas concretos vivenciados nas escolas, e assim orientar a construção de projetos pedagógicos curriculares que melhor qualifiquem os professores para o exercício da docência.  </li>
                                                        </ul>
                                                    </div>
													<div className="course-desc">
														<p>São <strong>objetivos específicos</strong> de cada eixo:</p>
													</div>
                                                    <div className="course-learn">
                                                        <h5>Eixo 1 - Mentoria</h5>
                                                        <ul className="list-unstyled">
                                                            <li><i className="fa fa-check"></i>Contribuir para a formação de professores pesquisadores e reflexivos, capazes de avaliar suas práticas e reorientar seu trabalho pedagógico;</li>
                                                            <li><i className="fa fa-check"></i>Proporcionar apoio e assessoria didática aos professores, minimizando os desafios da inserção profissional e se integrando de forma crítica e reflexiva ao sistema de ensino e a realidade escolar;</li>
                                                            <li><i className="fa fa-check"></i>Ressignificar o papel dos atores – universidade ou formadores – que passam a atuar como suporte externo dos professores em ação, em uma proposta formativa partilhada e centrada na escola;</li>
                                                            <li><i className="fa fa-check"></i>Contribuir para a permanência dos professores no magistério.</li>
                                                        </ul>
                                                    </div>
                                                    <div className="course-learn">
                                                        <h5>Eixo 2 - Assistência à docência</h5>
                                                        <ul className="list-unstyled">
                                                            <li><i className="fa fa-check"></i>Proporcionar aos alunos das licenciaturas o contato com a escola em atividades supervisionadas que favoreçam a sua inserção futura na profissão e despertem o interesse e a vocação para a docência.</li>
                                                            <li><i className="fa fa-check"></i>Instituir uma proposta inicial de indução à docência dos alunos do ICET através de um processo de socialização profissional dos licenciandos ainda durante a graduação.</li>
                                                        </ul>
                                                    </div>
                                                    <div className="course-learn">
                                                        <h5>Eixo 3 - Pesquisa</h5>
                                                        <ul className="list-unstyled">
                                                            <li><i className="fa fa-check"></i>Compreender os problemas educacionais do ensino de ciências da realidade investigada e formular alternativas para o seu enfrentamento;</li>
                                                            <li><i className="fa fa-check"></i>Identificar quais os principais problemas enfrentados pelos professores iniciantes em sua atuação nas escolas e quais recursos utilizam para fazer frente a esses desafios;</li>
															<li><i className="fa fa-check"></i>Analisar a pertinência dos conhecimentos, habilidades e competências desenvolvidos nos alunos das licenciaturas durante a graduação;</li>
															<li><i className="fa fa-check"></i>Mapear as fragilidades dos cursos de licenciatura oferecidos pelo ICET na concepção de seus egressos inseridos no mercado de trabalho.</li>
                                                        </ul>
                                                    </div>

                                                </Tab.Pane>
                                                <Tab.Pane eventKey="instructor" className="instructor-tab">
														{
														this.state.dbProjetoPesquisadores.map((pesquisador) => (
														<div className="instructor-item">
															<Row>
																<Col md="4">
																	<div className="instructor-img">
																		<img src={process.env.PUBLIC_URL + `/assets/images/${pesquisador.fotoPequena}`} alt="" className="img-fluid" />
																	</div>
																</Col>
																<Col md="8">
																	<div className="instructor-content">
																		<div className="instructor-box">
																			<div className="top-content d-flex justify-content-between">
																				<div className="instructor-name">
																					<h6>{pesquisador.nomeCompleto}</h6>
																					<p>{pesquisador.legenda}</p>
																				</div>
																				<div className="instructor-social">
																					<ul className="social list-unstyled list-inline">
																						<li className="list-inline-item">
																							<a href={pesquisador.orcid} target="_blank" rel="noopener noreferrer">
																								<img src={process.env.PUBLIC_URL + `/assets/images/iconID_48_48.jpg`} alt="Lattes" />
																							</a>
																						</li>
																						<li className="list-inline-item">
																							<a href={pesquisador.lattes} target="_blank" rel="noopener noreferrer">
																								<img src={process.env.PUBLIC_URL + `/assets/images/iconLATTES_48_48.jpg`} alt="Lattes" />
																							</a>
																						</li>
																					</ul>
																				</div>
																			</div>
																			<div className="instructor-desk">
																				<p>{pesquisador.qualificacao}</p>
																			</div>
																		</div>
																	</div>
																</Col>
															</Row>														
														</div>
														))
														}
                                                </Tab.Pane>
												<Tab.Pane eventKey="review" className="overview-tab">
                                                    <div className="course-desc">
                                                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;O presente projeto foi submetido e aprovado na Chamada MCTIC/CNPq Nº 05/2019 – PROGRAMA CIÊNCIA NA ESCOLA Ensino de Ciências na Educação Básica com o número de Processo: 441001/2019-0.</p>
                                                    </div>
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </Tab.Container>
                                    </div>
                                </div>
                            </Col>
                            <Col lg="3" md="4" sm="12">
								{/*<div className="single-details-sidbar">
                                    <Row>
                                        <Col md="12">
                                            <div className="course-details-feature">
                                                <h5 className="title">Detalhs do Projeto</h5>
                                                <ul className="list-unstyled feature-list">
                                                    <li><i className="las la-calendar"></i> Início Date: <span>????</span></li>
                                                    <li><i className="las la-clock"></i> Duração: <span>????</span></li>
                                                    <li><i className="las la-graduation-cap"></i> Estudo: <span>????</span></li>
                                                    <li><i className="las la-book"></i> Palestrantes: <span>???</span></li>
                                                    <li><i className="las la-bookmark"></i> Inscritos: <span>236</span></li>
                                                </ul>
                                                {/*<button type="button" className="enroll-btn">Enroll Course</button>*/}
													{/*</div>
                                        </Col>
                                    </Row>
                                </div>
								*/}
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Styles>

            {/* Footer 2 */}
            <Footer />

        </div >
    )
}
}
export default CourseDetails