import React, { Component } from 'react';
//import Datas from '../../data/event/events.json';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import { StylesPaginacao } from "./styles/pagination.js";
//import CourseSidebar from '../courses/components/CourseSidebar';
import Footer from '../../components/Footer';
import { Styles } from './styles/event.js';

class Events extends Component {
	//Construtor para listar eventos
	constructor(props) {
		super(props);
		this.pgAnt = null;
		this.pgNov = null;
		this.state=({
			db: [],
			dbPaginacao: []
		});
		this.paginacaoEventos();
	}
	
	listarEventos(pagina) {
			fetch("http://www.gpefed.com.br/src/api/?acao=listarEventos&pagina="+pagina)
			.then((response)=>response.json())
			.then((responseJson)=>
			{
				this.setState({
					db: responseJson
				});
				//console.log(this.state.db);
			})
	}
	
	paginacaoEventos() {
			fetch("http://www.gpefed.com.br/src/api/?acao=paginacaoEventos")
			.then((response)=>response.json())
			.then((responseJsonPaginacao)=>
			{
				this.setState({
					dbPaginacao: responseJsonPaginacao
				});
				//console.log(this.state.dbPaginacao);
			})
	}
	
    render() {
		this.pgNov = this.props.match.params.pagina;
		if( this.pgAnt !== this.pgNov ) {
			this.listarEventos(this.props.match.params.pagina);
			this.pgAnt = this.props.match.params.pagina;
		}
		
        return (
            <Styles>
                {/* Main Wrapper */}
                <div className="main-wrapper event-page">

                    {/* Header 2 */}
                    <HeaderTwo />

                    {/* Breadcroumb */}
                    <BreadcrumbBox title="Eventos" />

                    {/* Events Area */}
                    <section className="event-page-area">
                        <Container>
                            <Row>
                                <Col lg="9" md="12">
                                    {
                                        this.state.db.map((data, i) => (
                                            <div className="event-box" key={i}>
                                                <Row>
                                                    <Col xl="3" lg="4" md="0">
                                                        <div className="event-img">
                                                            <Link to={process.env.PUBLIC_URL + `/event-details/${data.id}` }><img src={process.env.PUBLIC_URL + `/assets/images/${data.fotoPequena}`} alt="" className="img-fluid" /></Link>
                                                        </div>
                                                    </Col>
                                                    <Col xl="9" lg="8" md="12">
                                                        <div className="event-content">
                                                            <div className="content-box">
                                                                <Row>
                                                                    <Col md="9">
                                                                        <div className="event-title">
                                                                            <h6><Link to={process.env.PUBLIC_URL + `/event-details/${data.id}` }>{data.titulo}</Link></h6>
                                                                        </div>
                                                                        <div className="event-time-location">
                                                                            <ul className="list-unstyled list-inline">
                                                                                <li className="list-inline-item"><i className="las la-clock"></i> {data.horario}</li>
                                                                                <li className="list-inline-item"><i className="las la-map-marker"></i> {data.local}</li>
                                                                            </ul>
                                                                        </div>
                                                                        <div className="event-desc">
                                                                            <p>{data.resumo}</p>
                                                                        </div>
                                                                    </Col>
                                                                    <Col md="3" className="text-center">
                                                                        <div className="event-date">
                                                                            <p>{data.data}</p>
                                                                        </div>
                                                                        <div className="join-btn">
																		{/*<Link to={process.env.PUBLIC_URL + data.eventLink}>Join Now</Link>*/}
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        ))
                                    }

                                    <Col md="12" className="text-center">
										<StylesPaginacao>
											{/* Pagination */}
											<ul className="pagination-box list-unstyled list-inline">
											{/*<li className="list-inline-item"><Link to={process.env.PUBLIC_URL + "/"}><i className="las la-angle-double-left"></i></Link></li>*/}
													{
														this.state.dbPaginacao.map((dataPaginacao, i) => {
															if ( String(dataPaginacao.pagina) === String(this.props.match.params.pagina) ) { 
																return <li className="active list-inline-item"><Link to={process.env.PUBLIC_URL + `/events/${dataPaginacao.pagina}`}>{dataPaginacao.pagina}</Link></li>
															} else {
																return <li className="list-inline-item"><Link to={process.env.PUBLIC_URL + `/events/${dataPaginacao.pagina}`}>{dataPaginacao.pagina}</Link></li>
															}
														})
													}
												{/*<li className="list-inline-item"><Link to={process.env.PUBLIC_URL + "/"}>2</Link></li>
												<li className="active list-inline-item"><Link to={process.env.PUBLIC_URL + "/"}>3</Link></li>
												<li className="list-inline-item"><Link to={process.env.PUBLIC_URL + "/"}>...</Link></li>
												<li className="list-inline-item"><Link to={process.env.PUBLIC_URL + "/"}>13</Link></li>*/}
												{/*<li className="list-inline-item"><Link to={process.env.PUBLIC_URL + "/"}><i className="las la-angle-double-right"></i></Link></li>*/}
											</ul>
										</StylesPaginacao>
                                    </Col>
                                </Col>

                                <Col lg="3" md="0">
								{/*<CourseSidebar />*/}
                                </Col>

                            </Row>
                        </Container>
                    </section>


                    {/* Footer */}
                    <Footer />

                </div>
            </Styles>
        )
    }
}

export default Events