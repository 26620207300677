import React, { Component } from 'react';
import Datas from '../data/about-us/about-us.json';
//import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import ModalVideo from 'react-modal-video';
import CountUp from 'react-countup';
import { Styles } from "./styles/aboutUs.js";

class AboutUs extends Component {
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }

    openModal() {
        this.setState({ isOpen: true })
    }

    render() {
        return (
            <Styles>
                {/* About Us */}
                <section className="about-us">
                    <Container>
                        <Row>
                            <Col md="6">
								{/*https://youtu.be/60vk_HDQ60w*/}
                                <div className="about-image">
                                    <img src={process.env.PUBLIC_URL + `/assets/images/${Datas.mainImage}`} className="main-img" alt="" />
                                    <img src={process.env.PUBLIC_URL + "/assets/images/pattern.png"} className="pattern-img" alt="" />
                                    <div className="video-player" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/nayana_fabricio_640_825.jpg)`}}>
                                        <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='60vk_HDQ60w' onClose={() => this.setState({ isOpen: false })} />
                                        <button onClick={this.openModal} className="play-button"><i className="las la-play"></i></button>
                                    </div>
                                </div>
                            </Col>
                            <Col md="6">
                                <div className="about-content">
                                    <h4 className="about-title">{Datas.title}</h4>
                                    <p className="about-para">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{Datas.desc1}<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{Datas.desc2}</span><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{Datas.desc3}</span></p>
                                    <Row>
                                        <Col sm="4">
                                            <div className="counter-box box1 text-center">
                                                <h3><CountUp end={12} duration={4} delay={1.0} /><i className="las"></i></h3>
                                                <p>Pesquisadores</p>
                                            </div>
                                        </Col>
                                        <Col sm="4">
                                            <div className="counter-box box2 text-center">
                                                <h3><CountUp end={8} duration={4} delay={1.0} /><i className="las"></i></h3>
                                                <p>Estudantes</p>
                                            </div>
                                        </Col>
                                        <Col sm="4">
                                            <div className="counter-box box3 text-center">
                                                <h3><CountUp end={4} duration={4} delay={1.0} /><i className="las"></i></h3>
                                                <p>Escolas</p>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
								<div className="about-content">
									<Row>
										<Col sm="4">
											<br/><br/>
											<div className="text-center">
												<a href="http://dgp.cnpq.br/dgp/espelhogrupo/609686" target="_blank" rel="noopener noreferrer">
													<img src={process.env.PUBLIC_URL + `/assets/images/logo_lattes_119_60.jpg`} alt="Diretório dos Grupos de Pesquisa no Brasil" />
												</a>
											</div>
										</Col>
									</Row>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Styles>
        )
    }
}

export default AboutUs
